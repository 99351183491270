import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import TableFilter from './TableFilter';
import "./Table.scss"
import UserModal from './UserModal';
import VesselModal from './VesselModal';

export default function Table({updateList, ...props}) {
  const [showPopup, setShowPopup] = useState(false)
  const displayMode = useSelector(state => state.displayMode)
  const DARK_DISPLAY_MODE = 'dark'

  const {
    getTableProps, 
    getTableBodyProps, 
    headerGroups, 
    page, 
    nextPage, 
    previousPage, 
    prepareRow, 
    canNextPage,
    canPreviousPage,
    pageOptions,
    state, 
    setGlobalFilter,
    selectedFlatRows
  } = props.tableProps

  const {pageIndex} = state

  useEffect(() => {
    if(selectedFlatRows.length !== 0) {
      setShowPopup(true)
    }
  }, [selectedFlatRows])

  return (
    <div className={displayMode === DARK_DISPLAY_MODE? "darkmode" : ''}>
      {selectedFlatRows.length > 0 &&
        (selectedFlatRows[0].original.mmsi ? 
          <VesselModal updateList={updateList} show={showPopup} onHide={() => setShowPopup(false)} vesselInfo={selectedFlatRows[0]}/>
          :
          <UserModal updateList={updateList} show={showPopup} onHide={() => setShowPopup(false)} userInfo={selectedFlatRows[0]}/>
        )
      }
      <TableFilter setFilter={setGlobalFilter} />
      <div style={{overflowX: 'auto'}}>
        <table {...getTableProps()} >
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map( column => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            <div 
                              className='table-header'
                            >
                                {column.render("Header")}
                                {column.isSorted ?
                                    (column.isSortedDesc ?
                                        <FontAwesomeIcon className='table-header-icon' icon={faSortDown} fontSize={12} />
                                    :
                                        <FontAwesomeIcon className='table-header-icon' icon={faSortUp} fontSize={12} />
                                    )
                                :
                                    <FontAwesomeIcon className='table-header-icon' icon={faSort} fontSize={12} />
                                }
                            </div>
                        </th>
                    ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {page.map(row => {
                    prepareRow(row)
                    return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map(cell => (
                        <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                        </td>
                        ))}
                    </tr>
                    )
                })}
            </tbody>
        </table>
      </div>
      <div className='pagination'>
        <div>
          Page{" "}
          <b>
            {pageIndex + 1} of {pageOptions.length}
          </b>
        </div>
        <div>
          <Button
            className='me-3'
            variant='contained'
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            size="large"
            style={{
                opacity: canPreviousPage ? 1 : 0.5,
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} fontSize={12} color="white"/>
          </Button>
          <Button
            variant='contained'
            onClick={() => nextPage()}
            disabled={!canNextPage}
            size="large"
            style={{
                opacity: canNextPage ? 1 : 0.5,
            }}
          >
            <FontAwesomeIcon icon={faArrowRight} fontSize={12} color="white"/>
          </Button>
        </div>
      </div>
    </div>
  )
}
