import {
  SETUP_LOGIN,
  SET_LOGOUT,
  SET_COLOR_BLIND,
  SET_CURRENT_TRAFFIC_LAYER,
  SET_FILTERED_COMPANIES,
  SET_SUPER_ADMIN,
  SET_UNIT_MEASUREMENT,
  SET_DISPLAY_MODE
} from "./types";
import { reactLocalStorage } from "reactjs-localstorage";

const INITIAL_STATE = {
  isLogin: false,
  user: null,
  colorBlind: false,
  trafficLayer: [],
  superAdmin: false,
  filteredCompanies: [],
  displayMode: 'light',
  unitMeasurement: 'ft'
};
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SETUP_LOGIN:
      reactLocalStorage.set("token", action.payload.token);
      reactLocalStorage.set("refreshToken", action.payload.refreshToken);
      return {
        ...state,
        isLogin: action.payload.isLogin,
        user: action.payload.user,
      };
    case SET_LOGOUT:
      reactLocalStorage.clear("token");
      reactLocalStorage.clear("refreshToken");
      return {
        INITIAL_STATE
      };
    case SET_CURRENT_TRAFFIC_LAYER:
      return {
        ...state,
        trafficLayer: action.payload,
      };
    case SET_FILTERED_COMPANIES:
      return {
        ...state,
        filteredCompanies: action.payload
      }
    case SET_SUPER_ADMIN:
      return {
        ...state,
        superAdmin: action.payload
      }
    case SET_COLOR_BLIND:
      return {
        ...state,
        colorBlind: action.payload
      }
    case SET_UNIT_MEASUREMENT:
      return {
        ...state,
        unitMeasurement: action.payload
      }
    case SET_DISPLAY_MODE:
      return {
        ...state,
        displayMode: action.payload,
      };
    default:
      return state;
  }
};
export default reducer;
