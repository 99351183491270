import { Button } from '@mui/material'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { errorToaster, successToaster } from '../../../utils/messageToast'
import { updateTemplate } from '../../../services'
import { withApollo } from '@apollo/client/react/hoc';
import { useSelector } from 'react-redux'

const DARK_DISPLAY_MODE = 'dark'

function TemplateUpdateModal({show, onHide, tripInfo, onSaveAs, id, ...props}) {
  const displayMode = useSelector(state => state.displayMode)

  const handleUpdateTemplate = async () => {
    let maxDraft = ""
    if(tripInfo.maxDraftFt !== "") {
      let draftInToDec = parseInt(tripInfo.maxDraftIn === "" ? 0 : tripInfo.maxDraftIn) / 12
      maxDraft = parseFloat((parseInt(tripInfo.maxDraftFt) + draftInToDec).toFixed(2))
      tripInfo.maxDraft = maxDraft
    }

    let maxHeight = ""
    if(tripInfo.maxHeightFt !== "") {
      let heightInToDec = parseInt(tripInfo.maxHeightIn === "" ? 0 : tripInfo.maxHeightIn) / 12
      maxHeight = parseFloat((parseInt(tripInfo.maxHeightFt) + heightInToDec).toFixed(2))
      tripInfo.maxHeight = maxHeight
    }

    let destinationsID = tripInfo.destinations.map((d) => {
      return {
        locationId: d.destination.id,
        legNumber: d.legNumber
      }
    })

    // Re-structure
    let templateData = {
      templateName: tripInfo.name,
      originId: tripInfo.origin.id,
      destinations: destinationsID,
      vesselId: tripInfo.vessel.id,
      maxHeight: tripInfo.maxHeight,
      maxDraft: tripInfo.maxDraft,
      id
    }

    try {
      const result = await updateTemplate(props, {...templateData})
      if(!result) {
        errorToaster("Unable to update template at the moment. Please try again later.")
        return
      }
      successToaster("Template updated!")
      onHide()
    } catch (error) {
      errorToaster("Unable to update template at the moment. Please try again later.")
      console.log(error)
    }
  }

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
      size={"m"}
      className={displayMode === DARK_DISPLAY_MODE? "darkmode" : ''}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Confirmation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Update <b>{tripInfo.name}</b> template?<br/><br/>
        Or <b>save as</b> a new template.
      </Modal.Body>
      <Modal.Footer className='justify-content-between'>
        <div>
          <Button 
            variant="contained"
            onClick={handleUpdateTemplate}
          >
            Update
          </Button>
          <Button 
            className='ms-3'
            variant="contained"
            onClick={onSaveAs}
          >
            Save As
          </Button>
        </div>
        <Button 
          variant="text" 
          onClick={onHide}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default withApollo(TemplateUpdateModal)