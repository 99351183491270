import React from 'react'
import TextField from '@mui/material/TextField';

export default function TableFilter({setFilter}) {
  return (
    <div>
        <TextField 
            variant="outlined"
            label="Search"
            onChange={e => setFilter(e.target.value)}
            size="small"
        />
    </div>
  )
}
