import { ApolloClient, InMemoryCache } from "@apollo/client";
import { ApolloLink, split, Observable } from "apollo-link";
import { reactLocalStorage } from "reactjs-localstorage";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";
import { handleRefreshToken } from "../services";
import { logout } from "../utils/helper";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
const defaultApolloOptions = {
  watchQuery: {
    fetchPolicy: "network-only",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "all",
  },
};
const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
});
const wsLink = new GraphQLWsLink({
  uri: process.env.REACT_APP_WS_LINK,
  options: {
    reconnect: true,
    timeout: 30000,
    connectionParams: {
      headers: {
        authorization: reactLocalStorage.get("token")
          ? `JWT ${reactLocalStorage.get("token")}`
          : "",
      },
    },
  },
});
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = reactLocalStorage.get("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        // eslint-disable-next-line
        switch (err.code) {
          // Apollo Server sets code to UNAUTHENTICATED
          // when an AuthenticationError is thrown in a resolver
          case 401:
            return new Observable(observer => {
              handleRefreshToken(apolloClient)
                .then(({ refreshToken }) => {
                  if (refreshToken.success) {
                    reactLocalStorage.set("token", refreshToken.token);
                    operation.setContext(({ headers = {} }) => ({
                      headers: {
                        // Re-add old headers
                        ...headers,
                        // Switch out old access token for new one
                        authorization: `JWT ${refreshToken.token}`,
                      },
                    }));
                  } else {
                    logout();
                  }
                })
                .then(() => {
                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  };
                  // Retry last failed request
                  return forward(operation).subscribe(subscriber);
                })
                .catch(error => {
                  // No refresh or client token available, we force user to login
                  observer.error(error);
                });
            });
        }
      }
    }
    // To retry on network errors, we recommend the RetryLink
    // instead of the onError link. This just logs the error.
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);
const links = [errorLink, authLink, splitLink];
const link = ApolloLink.from(links);
export const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: defaultApolloOptions,
});
