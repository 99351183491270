import { withApollo } from "@apollo/client/react/hoc";
import { faMap } from '@fortawesome/free-regular-svg-icons';
import { faShip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { loadModules } from 'esri-loader';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { MinusCircle } from 'react-feather';
import 'react-pulse-dot/dist/index.css';
import { useDispatch, useSelector } from "react-redux";
import LoadingIcon from "../../images/boat.gif";
import MapPin from '../../images/map-pin.svg';
import MapPin_ColorBlind from '../../images/map-pin_colorBlind.svg';
import { fetchCompanyTrips } from '../../services';
import { setFilteredCompanies, setSuperAdmin } from "../../store/action";
import { addEditDeleteTrip, calculateEtaHoursAndMinutes, checkVesselAvailability } from "../../utils/helper";
import { errorToaster } from '../../utils/messageToast';
import TripModal from '../schedulePlanning/TripModal';

const GRAY_COLOR = "#E6E6E6"

function Dashboard(props) {
  const mapRef = useRef(null)
  const dispatch = useDispatch()
  const vesselInfo = useSelector(state => state.user?.company?.vessels)
  const userInfo = useSelector(state => state.user)
  const filteredCompanies = useSelector(state => state.filteredCompanies)
  const superAdmin = useSelector(state => state.superAdmin)

  const [numActiveTrips, setNumActiveTrips] = useState(0)
  const [numPausedTrips, setNumPausedTrips] = useState(0)
  const [numAvailableVessels, setNumAvailableVessels] = useState(0)
  const [vesselsCoords, setVesselsCoords] = useState([])
  const [showPopup, setShowPopup] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showTripModal, setShowTripModal] = useState(false)
  const [activeTrips, setActiveTrips] = useState([])
  const [mapLayers, setMapLayers] = useState(null)
  const [companyTripList, setCompanyTripList] = useState(null)

  const colorBlind = useSelector(state => state.colorBlind)
  const displayMode = useSelector(state => state.displayMode)
  const DARK_DISPLAY_MODE = 'dark'

  useEffect(() => {
    document.title = 'RippleGo Dispatcher | Dashboard'

    const getActiveTrips = async () => {
      setLoading(true)
      try {
        const result = await fetchCompanyTrips(props, {
          isActive: true,
          company: "all"
        })
  
        let vesselCoords = []
        let companyList = []
        let companyTripList = new Map()
        if(result?.getCompanyTrips?.length > 0) {
          for(let trip of result.getCompanyTrips) {
            if(trip.statuses.at(-1).status === "PAUSE") {
              setNumPausedTrips(prevCount => prevCount + 1)
            }
            else {
              setNumActiveTrips(prevCount => prevCount + 1)
            }

            vesselCoords.push({
              ...trip,
              latitude: trip.currentPosition.coordinates[1],
              longitude: trip.currentPosition.coordinates[0],
            })
            let companyName = trip.user.company.name
            if(companyTripList.has(companyName)) {
              companyTripList.set(companyName, {
                num: companyTripList.get(companyName).num + 1,
                trip: [...companyTripList.get(companyName).trip, trip]
              })
            }
            else {
              companyTripList.set(companyName, {num: 1, trip: [trip]})
            }
            if(!companyList.includes(trip.user.company.name)) {
              companyList.push(trip.user.company.name)
            }
          }
          setCompanyTripList(companyTripList)
          setActiveTrips(vesselCoords)
        }
        else {
          setNumPausedTrips(0)
          setNumActiveTrips(0)
        }

      } catch (error) {
        console.log(error)
        errorToaster("Unable to fetch company's trip at the moment. Please try again later.")
      }
      setLoading(false)
    }

    if(userInfo.company.name === "Trabus Technologies") {
      dispatch(setSuperAdmin(true))
    }
    else {
      dispatch(setSuperAdmin(false))
    }
    getActiveTrips()
    .catch(console.error)
    
    if(vesselInfo) {
      setNumAvailableVessels(checkVesselAvailability(vesselInfo).available)
    }

    // Please do not remove the comment below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vesselInfo, userInfo])

  // Create map and layers
  useEffect(() =>{
    loadModules([
      'esri/Map', 
      'esri/views/MapView', 
      "esri/layers/GeoJSONLayer",
      "esri/widgets/Home",
      'esri/widgets/LayerList',
      "esri/layers/GroupLayer",
      'esri/widgets/BasemapGallery',
      'esri/widgets/Expand',
      "esri/layers/WMSLayer",
      "esri/core/reactiveUtils"
    ])
      .then(([
        Map, 
        MapView, 
        GeoJSONLayer,
        Home,
        LayerList,
        GroupLayer,
        BasemapGallery,
        Expand,
        WMSLayer,
        reactiveUtils
      ]) => {
        if(!mapRef.current) return

        const weatherRadarLayer = new WMSLayer({
          title: "Weather Radar",
          url: "https://mapservices.weather.noaa.gov/eventdriven/services/radar/radar_base_reflectivity/MapServer/WMSServer?request=GetCapabilities&service=WMS",
          listMode: "hide-children",
          visible: false
        })

        const IENCLayer = new WMSLayer({
          title: "IENC",
          url: "https://ienccloud.us/arcgis/services/IENC/USACE_IENC_Master_Service/MapServer/WMSServer?request=GetCapabilities&service=WMS",
          listMode: "hide-children",
          visible: false
        })

        const waterwayLayer = new GeoJSONLayer({
          title: "Inland Waterway",
          url: process.env.REACT_APP_INLAND_WATERWAY,
          renderer: {
            type: "simple",
            symbol: {
              type: 'simple-marker',
              style: "circle",
              color: [100,149,237],
              size: 2,
              outline: {
                width: 0
              }
            }
          },
          visible: false
        })

        const groupLayers = new GroupLayer({
          title: "Map Layers",
          visible: true,
          visibilityMode: "independent",
          layers: [IENCLayer, weatherRadarLayer, waterwayLayer],
          opacity: 0.75
        });
        setMapLayers(groupLayers)

        const map = new Map({
          basemap: displayMode === DARK_DISPLAY_MODE ? 'dark-gray-vector' : 'gray-vector',
          layers: [groupLayers]
        });

        const view = new MapView({
          container: mapRef.current,
          map: map,
          center: [-91, 35],
          zoom: 4
        });

        const basemapGallery = new BasemapGallery({ view: view });
        const homeWidget = new Home({ view: view });
        const expand = new Expand({
          view: view,
          content: basemapGallery,
          expanded: false,
        });
        const layerList = new LayerList({
          view: view,
          icon: 'watershed'
        })

        view.ui.add(layerList, 'top-left');
        view.ui.add(expand, 'top-right');
        view.ui.move("zoom", "bottom-right");
        view.ui.add(homeWidget, "bottom-right");

        // Event handler that fires each time an action is clicked.
        reactiveUtils.on(
          () => view.popup,
          "trigger-action",
          (event) => {
            // Execute the measureThis() function if the measure-this action is clicked
            if (event.action.id === "view-trip") {
              setShowPopup(event.action.info)
              setShowTripModal(true)
            }
          }
        );
      })
  }, [activeTrips, companyTripList, displayMode])

  // Add active trips onto map
  useEffect(() => {
    loadModules([ 'esri/Graphic', 'esri/layers/GraphicsLayer', 'esri/PopupTemplate' ])
    .then(([ Graphic, GraphicsLayer, PopupTemplate]) => {
      if(!mapLayers || !companyTripList) return
      for(let [key,val] of companyTripList.entries()) {
        const layerExists = mapLayers.layers.find(layer => layer.id === key)
        if(layerExists) {
          layerExists.visible = filteredCompanies.includes(key)
          continue
        } 
  
        const tripLayer = new GraphicsLayer({id: key, title: key})
        for(let trip of val.trip) {
          const tripPoint = new Graphic({
            geometry: {
              type: 'point',
              longitude: trip.currentPosition.coordinates[0],
              latitude: trip.currentPosition.coordinates[1],
            },
            symbol: {
              type: 'simple-marker',
              color: trip.statuses.at(-1).status === 'PAUSE' ? "#CD2C2C" : (colorBlind? "#19168c":"#008F85"),
              size: '16px',
              outline: {
                color: 'white',
                width: 1
              }
            },
            attributes: {
              ...trip,
              ObjectID: trip.id
            },
            popupTemplate: new PopupTemplate({
              title: trip.name,
              content: `
                <div style='border-left: 5px solid ${trip.statuses.at(-1).status === 'PAUSE' ? "#CD2C2C" : (colorBlind? "#19168c":"#008F85")}; padding-left: 5px; border-radius: 5px'>
                  ${superAdmin ? `Company Name: <b>${trip.user.company.name}</b></br>`: ""}
                  Origin: <b>${trip.origin.name}, ${trip.origin.city}</b> </br>
                  Destination: <b>${trip.destinations.at(-1).destination.name}, ${trip.destinations.at(-1).destination.city}</b> </br>
                  Operator: <b>${trip.user.firstName ? (trip.user.firstName + " " + trip.user.lastName) : trip.user.username}</b> </br>
                  Vessel: <b>${trip.vessel.name} (${trip.vessel.mmsi})</b> </br>
                  Departure: <b>${moment(trip.departureDate).format("MMM DD, YYYY HH:mm")}</b> </br>
                  ETA: <b>${calculateEtaHoursAndMinutes(moment.now(), trip.destinations.at(-1).eta)}</b> </br>
                  Last updated at: <b>${moment(trip.positionUpdatedAt).format("MMM DD, YYYY HH:mm")}</b> </br>
                  ${trip.statuses.at(-1).status === 'PAUSE' ? `Reason: <b>${trip.statuses.at(-1).reason}</b>` : "" }
                </div>
              `,
              actions: [{
                title: "View Trip",
                id: "view-trip",
                image: (colorBlind? MapPin_ColorBlind:MapPin),
                info: {
                  ...trip,
                  tripName: trip.name
                }
              }]
            })
          })
          tripLayer.add(tripPoint)
        }
        tripLayer.visible = filteredCompanies.includes(key)
        tripLayer.listMode = 'hide'
        mapLayers.add(tripLayer)
      }
    })
  }, [mapLayers, companyTripList, superAdmin, filteredCompanies, colorBlind])

  const handleTripUpdate = (trip, type) => {
    let updateVesselCoords = addEditDeleteTrip(trip, vesselsCoords, type)

    setVesselsCoords(updateVesselCoords)
    setShowPopup(null)
  }

  const filterCompanies = name => {
    if(name === 'all') {
      let names = Array.from(companyTripList.entries()).map(([key]) => {
        return key
      })
      dispatch(setFilteredCompanies(names))
      return
    }
    else if (name === 'deselect') {
      dispatch(setFilteredCompanies([]))
      return
    }
    let updatedFilter = [...filteredCompanies]
    let index = updatedFilter.indexOf(name)
    if(index > -1) {
      updatedFilter.splice(index, 1)
    }
    else {
      updatedFilter.push(name)
    }
    dispatch(setFilteredCompanies(updatedFilter))
  }

  if(loading) {
    return (
      <img className='loading' style={{marginTop: '20%'}} src={LoadingIcon} alt="Loading..." />
    )
  }

  return (
    <div>
      {superAdmin && companyTripList &&
        <div>
          <h2 style={{marginTop: 10}}>
            Company Filters
          </h2>

          <div>
            <Button
              className="m-1"
              variant="outlined"
              size={"small"}
              onClick={() => filterCompanies((filteredCompanies.length === companyTripList?.size ? 'deselect': "all"))}
            >
              {filteredCompanies.length === companyTripList?.size ? "Deselect All" : "Select All"}
            </Button><br/>
           
            {Array.from(companyTripList.entries()).map(([key, val]) => (
              <Button
                className="m-1"
                key={key}
                variant="contained"
                size={"small"}
                onClick={() => filterCompanies(key)}
                style={{color: filteredCompanies.includes(key) ? 'white' : displayMode === DARK_DISPLAY_MODE ? 'white' : 'black'}}
                color={(filteredCompanies.includes(key) ? 'primary' : 'secondary')}
                sx={{
                  backgroundColor: filteredCompanies.includes(key) ? 'primary' : displayMode === DARK_DISPLAY_MODE ? '#2F2F2F' : 'white',
                  '&:hover':{
                    backgroundColor: filteredCompanies.includes(key) ? 'primary' : displayMode === DARK_DISPLAY_MODE ? '#4F4F4F' : GRAY_COLOR,
                  }
                }}
              >
                {key} ({val.num})
              </Button>
            ))}
          </div>
        </div>
      }

      <hr style={{opacity: 0.15}}/>

      <h2 style={{marginTop: 20}}>
        Dashboard
      </h2>
      <div className='summary-cards'>
        <div
          style={{
            color : displayMode === DARK_DISPLAY_MODE? "white" : '#000A18',
            background: displayMode === DARK_DISPLAY_MODE? "#2F2F2F" : 'white'
          }} 
          className={'card-detail ' + (colorBlind? "color-blind-card" : "")}>
        <div>
              <h6>Active Trips</h6>
              <h4>{numActiveTrips}</h4>
          </div>
          <FontAwesomeIcon className='card-icon' icon={faMap} fontSize={25} style={{opacity: displayMode === DARK_DISPLAY_MODE? "1" : '0.2'}}/>
        </div>

        <div
          style={{
            color : displayMode === DARK_DISPLAY_MODE? "white" : '#000A18',
            background: displayMode === DARK_DISPLAY_MODE? "#2F2F2F" : 'white'
          }} 
          className={'card-detail ' + (colorBlind? "color-blind-card" : "")}>
        <div>
              <h6>Paused Trips</h6>
              <h4>{numPausedTrips}</h4>
          </div>
          <MinusCircle className='card-icon' style={{opacity: displayMode === DARK_DISPLAY_MODE? "1" : '0.2'}}/>
        </div>

        <div
          style={{
            color : displayMode === DARK_DISPLAY_MODE? "white" : '#000A18',
            background: displayMode === DARK_DISPLAY_MODE? "#2F2F2F" : 'white'
          }} 
          className={'card-detail ' + (colorBlind? "color-blind-card" : "")}>
        <div>
              <h6>Available Vessels</h6>
              <h4>{numAvailableVessels}</h4>
          </div>
          <FontAwesomeIcon className='card-icon' icon={faShip} fontSize={25} style={{opacity: displayMode === DARK_DISPLAY_MODE? "1" : '0.2'}}/>
        </div>
      </div>

      <h2 style={{marginTop: 10}}>
        Active Vessels Position
      </h2>
      <div 
        ref={mapRef}
        style={{
          width: '100%', 
          height: '64vh',
          minHeight: 400,
          borderRadius: 10
        }}
      />

      {showTripModal &&
        <TripModal
          show={showTripModal}
          onHide={() => setShowTripModal(false)}
          tripInfo={showPopup}
          disabled={false} 
          handleTripUpdate={(showPopup, type) => {
            setShowTripModal(false)
            handleTripUpdate(showPopup, type)
          }}
        />
      }
    </div>
  )
}

export default withApollo(Dashboard)