import { toast } from "react-toastify";

export const errorToaster = (msg,toastId) => {
  return toast.error(msg, {
    position: "bottom-right",
    autoClose: 5000,
    closeOnClick: true,
    toastId:toastId
  });
};

export const successToaster = msg => {
  return toast.success(msg, {
    position: "bottom-right",
    autoClose: 4000,
    closeOnClick: true,
  });
};

export const warningToaster = msg => {
  return toast.warn(msg, {
    position: "bottom-right",
    autoClose: 5000,
    closeOnClick: true,
    style: {
      color: '#893302'
    }
  })
}

