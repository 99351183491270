import { Button, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLanguage } from '@fortawesome/free-solid-svg-icons'

export default function NotFound() {
  const [imageSrc, setImageSrc] = useState("")
  const [translate, setTranslate] = useState(false)
  
  useEffect(() => {
    document.title = 'RippleGo Dispatcher | 404 Page Not Found'

    // Randomly choose a cat gif 
    const randomNumber = Math.ceil(Math.random() * 4);
    import(`../images/cat-stare-${randomNumber}.gif`).then((image) => {
      setImageSrc(image.default)
    })

  },[])

  return (
    <div>
      {imageSrc && <img className='loading cat shadow' src={imageSrc} alt="404 Page Not Found"/>}
      <div className='page-404'>
        <div className='d-flex'>
          <Tooltip title="Translator" placement='top' arrow>
            <FontAwesomeIcon className='page-404-translate pointer' size='xl' icon={faLanguage} onClick={() => setTranslate(!translate)}/>
          </Tooltip>

          {translate ?
            <div>
              HEY! Looks like you've stumbled into my kingdom of fabulousness, but this page seems to have vanished into thin air. Guess it couldn't handle the level of cattitude I bring. Time to unleash my pawsome skills and get you back on track!
            </div>
          :
            <div>
              MEOW! Meow meow me'ow meow meow meow meow meow meow, meow meow meow meow meow meow meow meow meow. Meow meow meo'w meow meow meow meow meow meow. Meow meow meow meow meow meow meow meow meow meow meow!
            </div>
          }

        </div>

        <Button
          className='mt-4'
          variant='outlined'
          size='large'
          href="/"
        >
          {translate ? "Direct Me Back Home!" : "MEOW!"}
        </Button>
        
      </div>

    </div>
  )
}
