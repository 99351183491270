import { withApollo } from '@apollo/client/react/hoc'
import { InputAdornment, TextField } from '@mui/material'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { ArrowLeft, ArrowRight, Search, Trash2 } from 'react-feather'
import { getCompanyTemplates } from '../../../services'
import DeleteModal from '../DeleteModal'
import TemplateMap from './TemplateMap'
import { useSelector } from 'react-redux'

const DARK_DISPLAY_MODE = 'dark'

function TemplateModal({show, onHide, templateOnSelect, ...props}) {
  const displayMode = useSelector(state => state.displayMode)

  const [templates, setTemplates] = useState([])
  const [showDelete, setShowDelete] = useState(false)
  const [deleteTemplate, setDeleteTemplate] = useState(null)
  const [filterTemplates, setFilterTemplates] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchAllTemplates = async () => {
      try {
        const result = await getCompanyTemplates(props)
        if(!result) return
        setTemplates(result.getTripTemplatesByCompany)
        setFilterTemplates(result.getTripTemplatesByCompany)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }
    
    fetchAllTemplates()
    // Please do not remove the comment below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSearchTripNames = e => {
    const name = e.target.value.toUpperCase()
    let filterData = templates.filter(d => d.templateName.toUpperCase().includes(name))
    setFilterTemplates(filterData)
  }

  const templateOnDelete = e => {
    let data = _.clone(e)
    data.tripName = data.templateName
    setDeleteTemplate(data)
    setShowDelete(true)
  }

  const handleTemplatesOnUpdate = id => {
    let updateList = templates.filter(d => d.id !== id)
    setFilterTemplates(updateList)
  }

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
      size={"xl"}
      className={displayMode === DARK_DISPLAY_MODE? "darkmode" : ''}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <ArrowLeft className='me-3' onClick={onHide} style={{cursor: "pointer"}}/>
          Templates
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
        
        {showDelete &&
          <DeleteModal 
            show={showDelete}
            onHide={() => setShowDelete(false)}
            tripInfo={deleteTemplate}
            deleteType="TEMPLATE"
            handleTripUpdate={handleTemplatesOnUpdate}
          />
        }

        <div className='p-3'>
          <TextField
            className='w-25'
            label="Search by trip name"
            onChange={onSearchTripNames}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
          />

          <div className='template-modal-cards mt-2'>
            {filterTemplates.length > 0 ?
              <>
                {filterTemplates.map((data, key) => (
                  <div key={key} className='template-modal-card'>
                    <div className='template-modal-transparent' onClick={() => templateOnSelect(data)}/>
                    <div className='template-modal-action delete' onClick={() => templateOnDelete(data)}><Trash2 /></div>
                    <TemplateMap 
                      dests={data.destinations}
                      origin={data.origin}
                    />
                    <div className='template-modal-card-name'>
                      {data.templateName} <br/>
                      <div className='center text-uppercase'>
                        <div className='text-truncate'>{data.origin.city? data.origin.city : data.origin.name}</div>
                        <ArrowRight className='mx-2'/> 
                        <div className='text-truncate'>{data.destinations.at(-1).destination.city? data.destinations.at(-1).destination.city : data.destinations.at(-1).destination.name}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
              :
              <div className='center w-100'>
                {loading ? "Loading..." : "No templates found."}
              </div>
            }

          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default withApollo(TemplateModal)