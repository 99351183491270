import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Button, FormControl, TextField, InputLabel, Select, MenuItem } from '@mui/material';
import { withApollo } from '@apollo/client/react/hoc';
import {Trash2} from "react-feather"
import { deleteVessel, editVessel } from '../../services';
import {useForm} from "react-hook-form"
import { successToaster } from '../../utils/messageToast';
import { useSelector } from 'react-redux';

function VesselModal({vesselInfo, show, onHide, updateList, ...props}) {
  const vessel = vesselInfo.original

  const [vesselState, setVesselState] = useState(vessel.state)
  const [confDelete, setConfDelete] = useState(false)
  const [deleteName, setDeleteName] = useState("")
  const [deleteError, setDeleteError] = useState(false)
  const displayMode = useSelector(state => state.displayMode)
  const DARK_DISPLAY_MODE = 'dark'

  const {
    register,
    reset,
    setError,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    reset({
      name: vessel.name,
      mmsi: vessel.mmsi,
      state: vessel.state
    })
  }, [vessel, reset])

  const handleDeleteVessel = async () => {
    // check if the delete confirmation name is the same as the vessel name
    if(deleteName !== vessel.name) {
      setDeleteError(true)
      return
    }

    const removeVessel = await deleteVessel(props, {
      id: vessel.id
    })

    if(removeVessel && removeVessel.deleteVessel.success) {
      updateList()
      show = false
      successToaster('Vessel deleted successfully!')
    }
  }

  const onSubmit = async (data) => {
    try {
      await editVessel(props, {
        id: vessel.id,
        ...data,
      })
      updateList()
      show = false
      successToaster('Vessel updated successfully!')
    } catch (error) {
      console.log(error)
      setError('mmsi', {message: "This MMSI already exists"})
    }
  }

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
      className={displayMode === DARK_DISPLAY_MODE? "darkmode" : ''}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Vessel - {vessel.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <small style={{opacity: 0.6}}>Please fill out the information below.</small>

        <form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
          <TextField
            {...register('name', {required: "Please enter vessel's name"})}
            className='text-field' 
            label="Vessel Name"
            variant='outlined'
            fullWidth={true}
            error={!!errors.name}
            helperText={errors?.name?.message}
            autoComplete='off'
          />

          <TextField
            {...register('mmsi', {required: "Please enter vessel's MMSI"})}
            className='text-field' 
            label="MMSI"
            variant='outlined'
            fullWidth={true}
            error={!!errors.mmsi}
            helperText={errors?.mmsi?.message}
            autoComplete='off'
          />

          <FormControl className='text-field mb-4' fullWidth>
            <InputLabel id="role-dropdown" required>Status</InputLabel>
            <Select
              {...register('state', {required: true})}
              labelId="role-dropdown"
              value={vesselState}
              label="Status"
              onChange={e => {
                setVesselState(e.target.value)
                setValue('state',  e.target.value)
              }}
            >
              <MenuItem value="AVAILABLE">AVAILABLE</MenuItem>
              <MenuItem value="UNAVAILABLE">UNAVAILABLE</MenuItem>
              <MenuItem value="UNDER_REPAIR">UNDER REPAIR</MenuItem>
            </Select>
          </FormControl>

          {confDelete &&
            <>
              <small>Please type in the vessel name to confirm.</small>
              <TextField
                className='mb-4 text-field'
                label="Confirm vessel name"
                onChange={e => setDeleteName(e.target.value)}
                fullWidth={true}
                required
                error={deleteError}
                helperText={deleteError ? "Please type in the vessel name to confirm deletion" : ""}
              />
            </>
          }

          <div className='d-flex justify-content-between'>
            <div className='center'>
              <Button
                variant='contained'
                type='submit'
              >
                <span className='px-2'>Update</span>
              </Button>
              {confDelete ?
                <Button
                  className='ms-4'
                  variant='contained'
                  color='error'
                  onClick={handleDeleteVessel}
                >
                  <div className='center'>
                    <Trash2 size={22} className="me-1"/>
                    <b>Delete?</b>
                  </div>
                </Button>
                :
                <Trash2 size={22} className="delete-user ms-4" onClick={() => setConfDelete(true)}/>
              }
            </div>
            <Button onClick={onHide}>Close</Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default withApollo(VesselModal)