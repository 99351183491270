import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import Isemail from 'isemail';
import { Button, FormControl, TextField, InputLabel, Select, MenuItem } from '@mui/material';
import { deleteUser, editUser } from '../../services';
import { useSelector } from 'react-redux';
import { withApollo } from '@apollo/client/react/hoc';
import {Trash2} from "react-feather"
import {useForm} from "react-hook-form"
import { errorToaster, successToaster } from '../../utils/messageToast';

function UserModal({userInfo, show, onHide, updateList, ...props}) {
  const user = userInfo?.original

  const [role, setRole] = useState(user.role ? user.role : "OPERATOR")
  const [confDelete, setConfDelete] = useState(false)
  const [deleteName, setDeleteName] = useState("")
  const [deleteError, setDeleteError] = useState(false)
  const displayMode = useSelector(state => state.displayMode)
  const DARK_DISPLAY_MODE = 'dark'

  const {
    register,
    reset,
    setError,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    setConfDelete(false)

    reset({
      firstName: user.firstName ?? "",
      lastName: user.lastName ?? "",
      email: user.email ?? "",
      username: user.username ?? "",
      role: user.role ?? "",
    })
  }, [user, reset])

  const handleDeleteUser = async () => {
    // check if the delete confirmation username is the same as the username
    if(deleteName !== user.username) {
      setDeleteError(true)
      return
    }

    const removeUser = await deleteUser(props, {
      id: user.id
    })

    if(removeUser && removeUser.deleteUser.success) {
      updateList()
      show = false
      successToaster("User deleted successfully!")
    }
  }

  const onSubmit = async (data) => {
    // Validate email address
    if(Isemail.validate(data.email, {errorLevel: true}) > 0) {
      setError('email', {message: "Please enter a valid email address"})
      return
    }

    try {
      await editUser(props, {
        ...data,
        id: user.id
      })
      updateList()
      show = false
      successToaster("User updated successfully!")
    } catch (error) {
      errorToaster("Unable to update the user at the moment. Please try again later.")
    }
  }

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
      className={displayMode === DARK_DISPLAY_MODE? "darkmode" : ''}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit User - {user.firstName} {user.lastName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <small style={{opacity: 0.6}}>Please fill out the information below.</small>

        <form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
          <div className='d-flex justify-content-between'>
            <TextField
              {...register("firstName", {required: "Please enter your first name"})}
              label="First Name"
              variant='outlined'
              autoComplete='off'
              error={!!errors.firstName}
              helperText={errors?.firstName?.message}
              style={{
                width: "48%",
              }}
            />

            <TextField
              {...register("lastName", {required: "Please enter your last name"})}
              label="Last Name"
              variant='outlined'
              autoComplete='off'
              error={!!errors.lastName}
              helperText={errors?.lastName?.message}
              style={{
                width: "48%"
              }}
            />
          </div>

          <TextField
            {...register("email", {required: "Please enter a valid email address"})}
            className='text-field' 
            label="Email"
            variant='outlined'
            fullWidth={true}
            error={!!errors.email}
            helperText={errors?.email?.message}
            autoComplete='off'
          />

          <TextField
            {...register("username", {required: "Please enter username"})}
            className='text-field' 
            label="Username"
            variant='outlined'
            fullWidth={true}
            error={!!errors.username}
            helperText={errors?.username?.message}
            autoComplete='off'
          />

          <FormControl className='text-field mb-4' fullWidth>
            <InputLabel id="role-dropdown" required>Role</InputLabel>
            <Select
              {...register("role", {required: true})}
              labelId="role-dropdown"
              value={role}
              label="Role"
              onChange={e => {
                setRole(e.target.value)
                setValue('role', e.target.value)
              }}
            >
              <MenuItem value="OPERATOR">OPERATOR</MenuItem>
              <MenuItem value="DISPATCHER">DISPATCHER</MenuItem>
            </Select>
          </FormControl>

          {confDelete &&
            <>
              <small>Please type in the username to confirm.</small>
              <TextField
                className='mb-4 text-field'
                label="Confirm username"
                onChange={e => setDeleteName(e.target.value)}
                fullWidth={true}
                autoComplete='off'
                error={deleteError}
                helperText={deleteError ? "Please type in the username to confirm deletion" : ""}
              />
            </>
          }

          <div className='d-flex justify-content-between mt-1'>
            <div className='center'>
              <Button
                variant="contained"
                type='submit'
              >
                <span className='px-2'>Update</span>
              </Button>
              {confDelete ?
                <Button
                  className='ms-4'
                  variant="contained"
                  color='error'
                  onClick={handleDeleteUser}
                >
                  <div className='center'>
                    <Trash2 size={22} className="me-1"/>
                    <b>Delete?</b>
                  </div>
                </Button>
                :
                <Trash2 size={22} className="delete-user ms-4" onClick={() => setConfDelete(true)}/>
              }
            </div>
            <Button onClick={onHide}>Close</Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default withApollo(UserModal)