import React, { useEffect, useRef, useState } from 'react'
import Highcharts from 'highcharts/highcharts-gantt';
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment';
import { isEmpty } from 'lodash';
import TripModal from '../TripModal';
import { calculateEtaHoursAndMinutes } from '../../../utils/helper';
import { useSelector } from 'react-redux';

const DARK_DISPLAY_MODE = 'dark'

const DEFAULT_PLOTOPTIONS = {
  series: {
    dataLabels: {
      enabled: true,
      format: '{point.name}',
      style: {
        fontWeight: 'normal',
        fontSize: '13px',
        textOutline: "0px",
      },  
    }
  }
}

export default function TripScheduler({tripsData, handleTripUpdate}) {
  const chartRef = useRef(null)
  const superAdmin = useSelector(state => state.superAdmin)

  const [selectedTrip, setSelectedTrip] = useState({})
  const [showTripModal, setShowTripModal] = useState(false)
  const [disableTripEdit, setDisableTripEdit] = useState(false)
  const colorBlind = useSelector(state => state.colorBlind)
  const displayMode = useSelector(state => state.displayMode)
  const [chartOptions, setChartOptions] = useState({
    chart: {
      backgroundColor: displayMode === DARK_DISPLAY_MODE? "#2F2F2F" : '#FFFFFF',
    },
    xAxis: {
      tickPixelInterval: 70,
      labels: {
        style: {
          color: displayMode === DARK_DISPLAY_MODE? '#FFFFFF' : 'black',
        }
      },
    },
    yAxis: {
      type: 'category',
      grid: {
        enabled: true,
        borderColor: displayMode === DARK_DISPLAY_MODE ? 'white' : 'rgba(0,0,0,0.2)',
        borderWidth: 1,
        columns: [
          {
            title: {
              text: 'Trip Name'
            },
            labels: {
              format: '{point.name}'
            }
          }, 
          {
            labels: {
              format: '{point.start:%b. %e, %Y}'
            },
            title: {
              text: 'Departure'
            }
          }, 
          {
            title: {
              text: 'Arrival'
            },
            labels: {
              format: '{point.end_date}'
            }
          }
        ]
      },
      labels: {
        style: {
          color: displayMode === DARK_DISPLAY_MODE? '#FFFFFF' : 'black',
        }
      }
    },
    accessibility: {
      enabled: false
    },
    navigator: {
      enabled: true,
      liveRedraw: true,
      series: {
        type: 'gantt',
        pointPlacement: 0.5,
        pointPadding: 0.25,
        accessibility: {
          enabled: false
        },
      },
      yAxis: {
        min: 0,
        max: 3,
        reversed: true,
        categories: []
      }
    },
    scrollbar: {
      enabled: true
    },
    rangeSelector: {
      enabled: true,
      selected: 5
    },
    plotOptions: {
      series: {
        ...DEFAULT_PLOTOPTIONS.series,
        events: {
          click: e => {
            setDisableTripEdit((e.point.statuses.length > 0 && e.point.statuses.at(-1).status === 'END') ? true : false)
            setSelectedTrip(e.point)
            setShowTripModal(true)
          }
        }
      }
    },
    tooltip: {
      useHTML: true,
      headerFormat: "",
      pointFormat: `
        <div>
          ${superAdmin ? "Company Name: <b>{point.companyName}</b><br/>" : ""}
          Trip name: <b>{point.name}</b> <br/>
          Origin: <b>{point.origin.name}, {point.origin.city}</b> <br/>
          Destination: <b>{point.destination.name}, {point.destination.city}</b> <br/>
          Operator: <b>{point.operator}</b> <br/>
          Vessel: <b>{point.vessel.name} ({point.vessel.mmsi})</b> <br/>
          Departure Date: <b>{point.departureDate}</b> <br/>
          ETA/Arrival: <b>{point.end_date}</b> <br/>
          Status: <b>{point.status}</b> <br/>
          Last updated at: <b>{point.lastUpdatedAt}</b> <br/>
        </div>
      ` 
    },
    series: [{
      data: []
    }],
    exporting: {
      sourceWidth: 1000
    },
    credits: {
      enabled: false
    },
  })

  useEffect(() => {
    const trips = [...tripsData]

    if(!trips || trips.length === 0) {
      return
    }

    let seriesData = []
    
    let y = 0
    for(let trip of trips) {
      if(trip.destinations.length === 0) continue

      const addEtaTime = trip.isActive ? moment.now() : trip.departureDate
      let end_date = calculateEtaHoursAndMinutes(addEtaTime, trip.destinations.at(-1).eta)
      let color = displayMode === DARK_DISPLAY_MODE? 'rgba(255, 255, 255, 0)' : 'white' // gray
      let borderColor = colorBlind? "#19168c":"#008F85" // ripplego green

      if(trip.destinations.at(-1).arrivedAt) {
        end_date = moment(trip.destinations.at(-1).arrivedAt).format("MMM DD, YYYY HH:mm")
      }

      if(trip.isActive) {
        color = trip.statuses.at(-1).status === "PAUSE" ? '#CD2C2C' : (colorBlind? "#19168c":"#008F85") // red : ripplego green
        borderColor = color
      }

      if(trip.statuses.length > 0 && trip.statuses.at(-1).status === 'END') {
        color = "#A4A4A4" 
        borderColor = color
      }

      seriesData.push({
        ...trip,
        companyName: trip.user.company.name,
        departureDate: moment(trip.departureDate).format("MMM DD, YYYY HH:mm"),
        lastUpdatedAt: moment(trip.positionUpdatedAt).format("MMM DD, YYYY HH:mm"),
        operator: trip.user.firstName ? (trip.user.firstName + " " + trip.user.lastName) : trip.user.username,
        destination: trip.destinations.at(-1).destination,
        status: trip.statuses.length > 0 ? trip.statuses.at(-1).status : "",
        start: new Date(trip.departureDate).getTime(),
        end: new Date(end_date).getTime(),
        end_date: end_date,
        name: trip.name,
        tripName: trip.name,
        color,
        borderColor: borderColor,
        pointWidth: 40,
        y,
        dataLabels: {
          enabled: true,
          color: displayMode === DARK_DISPLAY_MODE? '#FFFFFF' : 'black'
        }
      })
      y++
    }

    setChartOptions({
      series: [{
        data: seriesData
      }],
      plotOptions: {
        DEFAULT_PLOTOPTIONS
      }
    })
  }, [tripsData, colorBlind, displayMode])


  return (
    <>
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        constructorType="ganttChart"
        options={chartOptions}
      />

      {!isEmpty(selectedTrip) &&
        <TripModal 
          show={showTripModal} 
          onHide={() => setShowTripModal(false)} 
          tripInfo={selectedTrip} 
          disabled={disableTripEdit}
          handleTripUpdate={(trip, type) => {
            setShowTripModal(false)
            handleTripUpdate(trip, type)
          }}
          action="EDIT"
        />
      }
    </>
  )
}
