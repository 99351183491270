import {Routes, Route, useNavigate, useLocation} from "react-router-dom"
import Navbar from './components/navbars/Navbar';
import NavbarTop from "./components/navbars/NavbarTop";
import { useSelector } from "react-redux";
import RouterLists from "./routers/Routers"
import Login from "./pages/login/Login";
import { useEffect, useState } from "react";
import './GlobalStyling.scss';
import { ThemeProvider } from "@mui/system";
import { darkTheme, theme, themeColorBlind, darkThemeColorBlind } from "./themes";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import 'react-tooltip/dist/react-tooltip.css'

import mapboxgl from 'mapbox-gl';
import BetaAgreementForm from "./components/agreementForm/BetaAgreementForm";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

window.Buffer = window.Buffer || require("buffer").Buffer; 

const DARK_GRAY_COLOR = "#212121"
const DARK_DISPLAY_MODE = 'dark'

function App() {
  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location.pathname
  const [modalOpen, setModalOpen] = useState(false)

  const isLogin = useSelector(state => state.isLogin)
  const colorBlind = useSelector(state => state.colorBlind)
  const displayMode = useSelector(state => state.displayMode)

  useEffect(() => {
    if(!isLogin) {
      return navigate('login')
    }
    else if(currentPath === "/login") {  // re-direct user back to dashboard page if they are logged in already
      return navigate('/')
    }
  }, [isLogin, currentPath, navigate])

  return (
    <ThemeProvider theme={
      displayMode === DARK_DISPLAY_MODE && colorBlind ? darkThemeColorBlind :
      displayMode === DARK_DISPLAY_MODE && !colorBlind ? darkTheme :
      displayMode !== DARK_DISPLAY_MODE && colorBlind ? themeColorBlind : theme
    }>
      <div>
        {isLogin ?
          <div className="d-flex"
            style={{
              transition: '0.6s ease all',
              backgroundColor: displayMode === DARK_DISPLAY_MODE ? DARK_GRAY_COLOR : 'white',
              color: displayMode === DARK_DISPLAY_MODE ? 'white' : DARK_GRAY_COLOR,
            }}
          >
            <div style={{
              width: '18%',
              minWidth: 260,
              maxWidth: 280
            }}>
              <Navbar />
            </div>
            <div style={{
              width: '100%',
              height: '100vh',
              overflowY: 'auto',
            }}>
              <NavbarTop/>
              <div 
                className={
                  colorBlind? 
                  'beta-banner shadow mb-3 color-blind-banner' : 
                  'beta-banner shadow mb-3'
                }
                style={{
                  backgroundColor: displayMode === DARK_DISPLAY_MODE ? 
                  DARK_GRAY_COLOR : 'white'
                }}
              >
                RippleGo BETA test version. View 
                <span className={'beta-agreement-text '+ colorBlind? 'color-blind-banner' : ''} onClick={() => setModalOpen(true)}>
                  BETA TEST AGREEMENT
                </span>
              </div>
              <div className="py-3 px-4 mx-2 mt-2">
                <ToastContainer 
                  bodyClassName="toastBody"
                  theme="colored"
                />
                <Routes>
                  {RouterLists.map((route, key) => (
                    <Route key={key} path={route.path} element={route.component}/>
                  ))}
                </Routes>
              </div>
            </div>
          </div>
          :
          <>
            <ToastContainer
              bodyClassName="toastBody"
              theme="colored"
            />
            <Routes>
              <Route path='login' element={<Login />}/>
            </Routes>
          </>
        }
      </div>
      <BetaAgreementForm
        modalOpen={modalOpen}
        modalOnClose={() => setModalOpen(false)}
      />
    </ThemeProvider>
  );
}

export default App;
