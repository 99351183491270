import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Button, TextField } from '@mui/material';
import { deleteTemplate, deleteTrip } from '../../services';
import { errorToaster, successToaster } from '../../utils/messageToast';
import { withApollo } from '@apollo/client/react/hoc';
import { useSelector } from 'react-redux';

const TRIP = "TRIP"
const TEMPLATE = "TEMPLATE"
const DARK_DISPLAY_MODE = 'dark'

function DeleteModal({show, onHide, tripInfo, handleTripUpdate, deleteType, ...props}) {
  const displayMode = useSelector(state => state.displayMode)
  const [deleteName, setDeleteName] = useState("")
  const [deleteError, setDeleteError] = useState(false)

  const onDelete = async () => {
    if(deleteName !== tripInfo.tripName) {
      setDeleteError(true)
      return
    }
    
    switch(deleteType) {
      case TRIP:
        try {
          const result = await deleteTrip(props, { id: tripInfo.id })
          if(result) {
            handleTripUpdate(tripInfo, 'delete')
          }
          successToaster("Trip deleted successfully!")
          onHide()
        } catch (error) {
          errorToaster("Unable to delete the trip at the moment. Please try again later.")
        }
        break;
      case TEMPLATE:
        try {
          const result = await deleteTemplate(props, {id: tripInfo.id})
          if(!result || !result.deleteTripTemplate?.success) {
            errorToaster("Unable to delete the trip at the moment. Please try again later.")
            return
          }
          handleTripUpdate(tripInfo.id)
          successToaster("Template deleted successfully!")
          onHide()
        } catch (error) {
          errorToaster("Unable to delete the trip at the moment. Please try again later.")
        }

        break;
      default:
        break;
    }
  }

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
      size={"m"}
      className={displayMode === DARK_DISPLAY_MODE? "darkmode" : ''}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Confirmation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Delete <b>{tripInfo.tripName}</b> {deleteType === TRIP ? "trip" : "template"}?<br/><br/>
        Please type in the trip name to confirm delettion.
        <TextField
          className='mt-3 mb-2'
          label="Confirm trip name"
          onChange={e => setDeleteName(e.target.value)}
          fullWidth={true}
          required
          error={deleteError}
          helperText={deleteError ? "Please type in the trip name to confirm deletion" : ""}
        />
      </Modal.Body>
      <Modal.Footer className='justify-content-between'>
        <Button 
          variant="contained"
          onClick={onDelete}
          color="danger"
        >
          Delete
        </Button>
        <Button 
          variant="text" 
          onClick={onHide}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default withApollo(DeleteModal)