import { Button, Modal, Rating, TextField, TextareaAutosize } from '@mui/material'
import React, { useEffect, useState } from 'react'
import validator from 'validator'
import { X } from 'react-feather'
import { useForm } from 'react-hook-form'
import { successToaster } from '../../utils/messageToast'

const FEEDBACK = 'feedback'

export default function ReportAndFeedbackForm({
    openForm,
    handleCloseForm,
    formType,
    displayMode
  }) {
    const {
      register,
      reset,
      handleSubmit,
      setError,
      formState: {errors}
    } = useForm({mode: 'onChange'})
  
    const [uxRating, setUxRating] = useState(null)
    const [overallRating, setOverallRating] = useState(null)
    const [featureRating, setFeatureRating] = useState(null)
    const [ratingError, setRatingError] = useState(false)
  
    useEffect(() => {
      reset()
      setUxRating(null)
      setOverallRating(null)
      setFeatureRating(null)
    }, [formType, reset])
  
    const encode = data => {
          return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&")
      }
  
    const onSubmit = (data, e) => {
      const form = e.target
  
      let isFormCompleted = true
      let validateEmail = validator.isEmail(data.email)
  
      if(!validateEmail) {
        setError('email', {
          type: 'manual',
          message: "Please enter a validate email address"
        })
        isFormCompleted = false
      }
  
      if(formType === FEEDBACK && (!uxRating || !overallRating || !featureRating)) {
        setRatingError(true)
        isFormCompleted = false
      }
  
      if(!isFormCompleted) {return}
     
      let updatedData = {...data}
  
      if(formType === FEEDBACK) {
        updatedData = {
          ...data,
          overallRating,
          featureRating,
          uxRating
        }
      }
  
      let fetchObj = {
        method: "POST",
        body: encode({
                  'form-name': form.getAttribute('name'),
          'subject': `${formType} Form Submission`,
                  ...updatedData,
              })
      }
      if(formType === FEEDBACK) {
        fetchObj = {
          ...fetchObj,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" 
          }
        }
      }
      else {
        fetchObj = {
          ...fetchObj,
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      }
  
      fetch('/', {
        ...fetchObj
      })
        .then(() => {
          reset()
          setUxRating(null)
          setOverallRating(null)
          setFeatureRating(null)
          successToaster("Thank you for your submission!")
          handleCloseForm()
        })
        .catch(error => console.log(error))
    }
  
    return (
      <Modal
        className='popup-modal center'
        open={openForm}
        onClose={handleCloseForm}
      >
        <div className={'popup-modal-content shadow ' + displayMode}>
          <div className='popup-modal-header'>
            <h3>{formType === FEEDBACK ? "Feedback" : "Report An Issue"}</h3> 
            <X className='popup-modal-x' onClick={handleCloseForm}/>
          </div>
          <form 
            className='popup-modal-form my-2' 
            onSubmit={handleSubmit(onSubmit)} 
            name={`${formType} form`} 
            method='POST' 
            data-netlify="true" 
            netlify-honeypot="bot-field" 
            data-netlify-recaptcha="true" 
            encType="multipart/form-data"
          >
            <input type="hidden" name="form-name" value={`${formType} form`}/>
            <p hidden>
                <label>
                    <b>Dummy <span className="text-danger">*</span></b>
                    <input name="bot-field"/>
                </label>
            </p>
            <div className='d-flex justify-content-between'>
              <div className={'popup-modal-card half ' + displayMode}>
                <TextField
                  {...register("name", {required: "Please enter your name"})}
                  className='popup-modal-textfield'
                  type='text'
                  fullWidth
                  label="Full name*"
                  variant='standard'
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                />
              </div>
              <div className={'popup-modal-card half ' + displayMode}>
                <TextField
                  {...register("companyName", {required: "Please enter your company name"})}
                  className='popup-modal-textfield'
                  type='text'
                  fullWidth
                  label="Company name*"
                  variant='standard'
                  error={!!errors.companyName}
                  helperText={errors?.companyName?.message}
                />
              </div>
            </div>
            <div className={'popup-modal-card ' + displayMode}>
              <TextField
                {...register("email", {required: "Please enter your email"})}
                className='popup-modal-textfield'
                label="Email*"
                type="email"
                fullWidth
                variant='standard'
                error={!!errors.email}
                helperText={errors?.email?.message}
              />
            </div>
            {formType === FEEDBACK ?
              <>
                <div className={'popup-modal-card ' + displayMode}>
                  <div className='popup-modal-rating'>
                    <small style={{opacity: 0.7}}>Overall Satisfaction*</small>
                    <Rating
                      name="overallRating"
                      type="number"
                      value={overallRating}
                      onChange={(_, val) => {
                        setOverallRating(val)
                      }}
                    />
                  </div>
                  <div className='popup-modal-rating my-2'>
                    <small style={{opacity: 0.7}}>Feature and functionality*</small><br/>
                    <Rating
                      name="featureRating"
                      type="number"
                      value={featureRating}
                      onChange={(_, val) => {
                        setFeatureRating(val)
                      }}
                    />
                  </div>
                  <div className='popup-modal-rating'>
                    <small style={{opacity: 0.7}}>User friendliness*</small><br/>
                    <Rating
                      name="uxRating"
                      type="number"
                      value={uxRating}
                      onChange={(_, val) => {
                        setUxRating(val)
                      }}
                    />
                  </div>
                  {ratingError && <small style={{ color: "#d32f2f", fontSize: 12}}>Please complete the rating section</small>}
                </div>
                <div className={'popup-modal-card ' + displayMode}>
                  <TextField
                    {...register("mostUsedFeature")}
                    className='popup-modal-textfield'
                    label="Which feature do you find most valuable?"
                    variant='standard'
                    fullWidth
                  />
                </div>
                <div className={'popup-modal-card ' + displayMode}>
                  <TextField
                    {...register("featureImprovement")}
                    className='popup-modal-textfield'
                    label="Are there any features you feel are missing or could be improved?"
                    variant='standard'
                    fullWidth
                  />
                </div>
              </>
            :
              <>
                <div className={'popup-modal-card ' + displayMode}>
                  <small style={{opacity: 0.7}}>Problem description*</small>
                  <TextareaAutosize
                    {...register("problemDescription", {required: "Please describe the issue you encountered"})}
                    className={'mt-1 popup-modal-textfield mui-textarea ' + displayMode}
                    type='text'
                    placeholder='Describe the issue'
                    minRows={2}
                  />
                  {errors?.description && <small style={{ color: "#d32f2f", fontSize: 12}}>{errors.description?.message}</small>}
                </div>
                <div className={'popup-modal-card ' + displayMode}>
                  <small style={{opacity: 0.7}}>Steps to reproduce the issue*</small>
                  <TextareaAutosize
                    {...register("stepsToReproduce", {required: "Please list the steps to reproduce the issue"})}
                    type='text'
                    className={'mt-1 popup-modal-textfield mui-textarea ' + displayMode}
                    placeholder='As detailed as possible'
                    minRows={2}
                  />
                  {errors?.steps && <small style={{ color: "#d32f2f", fontSize: 12}}>{errors.steps?.message}</small>}
                </div>
              </>
            }
            <div data-netlify-recaptcha="true"></div>
            <Button
              className='mt-4'
              type='submit'
              variant='contained'
              fullWidth
            >
              {formType === FEEDBACK ? "Submit feedback": "submit report"}
            </Button>
          </form>
        </div>
      </Modal>
    )
  }
  

