import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setColorBlind, setDisplayMode, setUnitMeasurement } from '../../store/action'
import { Button, Radio, Box as MUIBox, TextField } from '@mui/material'
import { errorToaster, successToaster } from '../../utils/messageToast'
import { passwordChange } from '../../services'
import { setLoading } from '../../store/action'
import { withApollo } from '@apollo/client/react/hoc'
import { Circle } from 'react-feather'
import "./Settings.scss"

const PRIMARY_COLOR = "#008F85"
const LIGHT_DISPLAY_MODE = "light"
const DARK_DISPLAY_MODE = "dark"

function Settings(props) {
    const dispatch = useDispatch()
    const displayMode = useSelector(state => state.displayMode)
    const colorBlind = useSelector(state => state.colorBlind)
    const unitMeasurement = useSelector(state => state.unitMeasurement)
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const handleDisplayChange = (e) => {
        dispatch(setDisplayMode(e.target.value))
    }

    const handleColorBlindChange = (e) => {
        let isChecked = e.target.checked
        dispatch(setColorBlind(isChecked))
    }

    const handleUnitChange = e => {
        let unit = e.target.value
        if(unit === 'm'){
            dispatch(setUnitMeasurement('m'))
        }else{
            dispatch(setUnitMeasurement('ft'))
        }
    }

    const submitPasswordChange = async (e) => {
        e.preventDefault()
    
        if(newPassword !== confirmPassword) {
          errorToaster("Password does not match")
          return
        }
    
        if(newPassword === currentPassword) {
          errorToaster("New password and current password shoudld not be the same")
          return
        }
    
        dispatch(setLoading(true))
        try {
          const result = await passwordChange(props, {
            oldPassword: currentPassword,
            newPassword1: newPassword,
            newPassword2: confirmPassword
          })
    
          if(!result.passwordChange.success) {
            errorToaster("Password changed failed, check your current password")
          }
          else {
            successToaster("Password changed successfully!")
            setCurrentPassword('')
            setNewPassword('')
            setConfirmPassword('')
          }
        } catch (error) {
          console.log(error)
          errorToaster("Password changed failed, check your current password")
        }
        dispatch(setLoading(false))
    }

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
          case 'currentPassword':
            setCurrentPassword(value);
            break;
          case 'newPassword':
            setNewPassword(value);
            break;
          case 'confirmPassword':
            setConfirmPassword(value);
            break;
          default:
            break;
        }
    }

    const miniCardComponent = (mode) => {
        return <>
          <div 
            className={'card shadow ' + mode + " "+ (colorBlind ? 'color-blind-card' : "")} 
            onClick={() => {
              dispatch(setDisplayMode(mode))
            }}
          >
            <div className='mini-card center'>
              <div className='w-100'>
                <div className='center'>
                  <Circle fill={PRIMARY_COLOR} color='transparent' size={15}/>
                  <div className='mini-rec'></div>
                </div>
    
                <div className='center mt-2'>
                  <Circle fill={PRIMARY_COLOR} color='transparent' size={15}/>
                  <div className='mini-rec'></div>
                </div>
              </div>
            </div>
    
            <div className='mini-card half center'></div>
          </div>
        </>
    }

    return (
        <div>
            <h2>Settings</h2>
            <hr/>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    <div className='mt-4 settings-appearance' style={{marginBottom: '20px'}}>
                        <div className='center justify-content-between w-100'>
                            <b>Unit Measurement</b>
                            <div style={{display:'flex'}}>
                                <div className='text-center' style={{display:'flex', width:'6vw'}}>
                                <div className={'mt-2 '+(unitMeasurement === 'ft' ? 'text-primary fw-bold' : "")}><small>ft</small></div>
                                <Radio
                                    checked={unitMeasurement === 'ft'}
                                    onChange={handleUnitChange}
                                    value = 'ft'
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'A' }}
                                />
                                </div>

                                <div className='text-center' style={{display:'flex', width:'6vw'}}>
                                <div className={'mt-2 '+(unitMeasurement === 'm' ? 'text-primary fw-bold' : "")}><small>m</small></div>
                                <Radio
                                    checked={unitMeasurement === 'm'}
                                    onChange={handleUnitChange}
                                    value = 'm'
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'B' }}
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 style={{marginBottom: '10px'}}>Appearance</h3>
                    <div className='settings-appearance' >
                        <div>
                            <b>Themes</b><br/>
                            <div className='center justify-content-between w-100'>
                                <div className='text-center' style={{width: '48%'}}>
                                    {miniCardComponent(LIGHT_DISPLAY_MODE)}
                                    <div className={'mt-2 ' + (displayMode === LIGHT_DISPLAY_MODE ? 'text-primary fw-bold' : "")}><small>Light</small></div>
                                    <Radio
                                    checked={displayMode === LIGHT_DISPLAY_MODE}
                                    onChange={handleDisplayChange}
                                    value={LIGHT_DISPLAY_MODE}
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'A' }}
                                    />
                                </div>

                                <div className='text-center' style={{width: '48%'}}>
                                    {miniCardComponent(DARK_DISPLAY_MODE)}
                                    <div className={'mt-2 ' + (displayMode === DARK_DISPLAY_MODE ? 'text-primary fw-bold' : "")}><small>Dark</small></div>
                                    <Radio
                                    checked={displayMode === DARK_DISPLAY_MODE}
                                    onChange={handleDisplayChange}
                                    value={DARK_DISPLAY_MODE}
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'B' }}
                                    />
                                </div>
                            </div>                    
                        </div>
                        <hr/>
                        <div className='center justify-content-between w-100'>
                            <b>Color Blind Friendly</b><br/>
                            <label className="switch me-3">
                                <input type="checkbox" onClick={handleColorBlindChange} defaultChecked={colorBlind}/>
                                <span className={"slider "+ (colorBlind ? 'color-blind-toggle' : "")}></span>
                            </label>                    
                        </div>
                    </div>                
                </div>
                <div>
                    <div className='mt-4 settings-appearance' style={{width: '46vw'}}>
                        <div className='center justify-content-between w-100'>
                            <div className='w-100'>
                                <MUIBox sx={{ width: '100%' }}>
                                <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    Change Password        
                                </div>
                                    <MUIBox
                                    component="form"
                                    sx = {{
                                        display: "flex",
                                        flexDirection: "column"
                                    }}
                                    onSubmit={submitPasswordChange}
                                    >
                                        <TextField
                                            label="Current Password"
                                            variant="outlined"
                                            type='password'
                                            name = "currentPassword"
                                            value={currentPassword}
                                            onChange = {handlePasswordChange}
                                            margin="normal"
                                            fullWidth
                                            required
                                        />
                                        <TextField
                                            label="New Password"
                                            variant="outlined"
                                            type='password'
                                            name = "newPassword"
                                            value={newPassword}
                                            onChange = {handlePasswordChange}
                                            margin="normal"
                                            fullWidth
                                            required
                                        />
                                        <TextField
                                            label="Confirm Password"
                                            variant="outlined"
                                            type='password'
                                            name = "confirmPassword"
                                            value={confirmPassword}
                                            onChange = {handlePasswordChange}
                                            margin="normal"
                                            fullWidth
                                            required
                                        />
                                        <Button type="submit" variant="contained" color="primary" sx={{mt:2}}>
                                            Change Password
                                        </Button>
                                    </MUIBox>         
                                </MUIBox>
                            </div>
                        </div>
                    </div>                
                </div>                
            </div>

        </div>
    )
}

export default  withApollo(Settings)