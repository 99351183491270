import React, { useEffect, useState } from 'react'
import { TextField, InputAdornment, List, ListItemText, ListItemButton } from '@mui/material';
import { GetSearchResult } from '../../services';
import { withApollo } from '@apollo/client/react/hoc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { Info } from 'react-feather';
import {Tooltip} from "react-tooltip";
import { errorToaster } from '../../utils/messageToast';
import { useSelector } from 'react-redux';

const ORIGIN_FIELD = 'ORIGIN'

function LocationSearch({
	handleSearchLocationOnClick, 
	removeDestination,
	clearErrors,
	type, 
	destNum, 
	locationName,
	disabled,
	...props
}) {
	const [locationList, setLocationList] = useState([])
	const [searchLocationName, setSearchLocationName] = useState("")
  const colorBlind = useSelector(state => state.colorBlind)

  useEffect(() => {
    if(locationName) {
      let city = locationName.city ? `, ${locationName.city.toUpperCase()}` : ""
      setSearchLocationName(`${locationName.name} ${city}`)
    }
  }, [locationName])

	const searchLocation = async text => {
		setSearchLocationName(text)
    if(text.length < 3) {
			if(text.length > 0 ) {
				setLocationList([{name: "Please enter more than three letters"}])	
			}
			else {
				setLocationList([])	
			}
			return
		}

    setLocationList([{name: "Loading..."}])

    try {
      let result = await GetSearchResult(props, {text})
      if(result.getLocations?.length > 0) {
        setLocationList(result.getLocations)
      } 
      else {
        setLocationList([{name: "Location name not found"}])
      }
    } catch (error) {
      errorToaster("Unable to search the location at the moment. Please try again later.")
    }
	}

	const selectLocation = location => {
		setLocationList([])
		setSearchLocationName(location.name)
		handleSearchLocationOnClick(location, type, destNum-1)
		clearErrors(type.toLowerCase())
	}

  return (
    <div className='trip-modal-destinations'>
      <TextField
        {...props}
        className='trip-modal-text-field'
        label={type === ORIGIN_FIELD ? "Origin" : ("Destination " + destNum)}
        variant="standard"
        autoComplete='off'
        fullWidth={true}
        value={searchLocationName !== "" ? searchLocationName : (locationName ?? "")}
        onChange={e => searchLocation(e.target.value)}
        error={props.error}
        helperText={props.helperText}
        disabled={disabled}
        InputProps={{
          endAdornment: 
          <InputAdornment position="end">
            <Info 
              color={colorBlind? "#19168c": "#008f8f"} 
              size={22}
              style={{cursor: "help"}}
              data-tooltip-id="mile-marker-tooltip"
              data-tooltip-html="Want to search for mile markers? <br/>Type (river name, mile) with parentheses. <br/> E.g. (ohio, 500)"
            />
            {!disabled && type !== ORIGIN_FIELD && destNum !== 1 &&
              <FontAwesomeIcon className='ms-2 me-1 pointer' icon={faX} size="sm" color={colorBlind? "#19168c":"#008F85"} onClick={() => removeDestination(destNum - 1)}/>
            }
            </InputAdornment>
        }}
      />
      {locationList.length > 0 &&
        <List className='trip-modal-list' disablePadding={true}>
          {locationList.map((location, key) => (
            <ListItemButton className='border-bottom' key={key} onClick={() => selectLocation(location)}>
              <ListItemText
                  primary={`${location.name} ${location.city ? `, ${location.city.toUpperCase()}` :  ""}`}
              />
            </ListItemButton>
          ))}
        </List>
      }
      <Tooltip 
        id="mile-marker-tooltip" 
        place='top'
        style={{
          textAlign: 'center',
          zIndex: 3
        }}
      />
    </div>
  )
}

export default withApollo(LocationSearch)