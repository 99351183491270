import React, {useRef, useEffect} from 'react'
import { Edit } from 'react-feather'
import { useSelector } from 'react-redux';

export const Actions = React.forwardRef(({indeterminate, ...rest}, ref) => {
    const defaultRef = useRef()
    const resolvedRef = ref || defaultRef
    const colorBlind = useSelector(state => state.colorBlind)

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
        <label className={'table-action-icon pointer center '+ (colorBlind? "color-blind-action" : "")}>
            <input type="checkbox" ref={resolvedRef} {...rest} hidden/>
            <Edit size={18}/>
        </label>
    )
})