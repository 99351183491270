export const mileIcons = num => {
  return `<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" fill="#1B2d5b" stroke="#050c1f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10">
  </circle>
  <text
      x="4.7"
      y="15"
      stroke-width="1"
      stroke="white"
      style="font-size: 9px; font-weight: normal"
    >
    ${num}
  </text>
  </svg>`}