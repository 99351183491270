import { withApollo } from '@apollo/client/react/hoc';
import { Autocomplete, Button, InputAdornment, TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { loadModules } from 'esri-loader';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { CSVLink } from 'react-csv';
import { Trash2 } from 'react-feather';
import { Controller, useForm } from "react-hook-form";
import { useSelector } from 'react-redux';
import { mileIcons } from '../../components/MarkerIcon/MarkerIcon';
import LoadingIcon from "../../images/boat.gif";
import MapPin1 from "../../images/map-pin1.svg";
import MapPin10 from "../../images/map-pin10.svg";
import MapPin2 from "../../images/map-pin2.svg";
import MapPin3 from "../../images/map-pin3.svg";
import MapPin4 from "../../images/map-pin4.svg";
import MapPin5 from "../../images/map-pin5.svg";
import MapPin6 from "../../images/map-pin6.svg";
import MapPin7 from "../../images/map-pin7.svg";
import MapPin8 from "../../images/map-pin8.svg";
import MapPin9 from "../../images/map-pin9.svg";
import OriginPin from "../../images/origin-pin.svg";
import { addTrip, calculateTrip, createTemplate, editTrip, getCsvDecklogs, getCsvPrevoyage, getMilePath, getTraffic } from '../../services';
import { calculateEtaHoursAndMinutes, downloadCSV, ftTom } from '../../utils/helper';
import { errorToaster, successToaster, warningToaster } from '../../utils/messageToast';
import DeleteModal from './DeleteModal';
import DraggableInputs from "./DraggableInputs";
import LocationSearch from './LocationSearch';

import MapPin10_CB from "../../images/map-pin10_colorBlind.svg";
import MapPin1_CB from "../../images/map-pin1_colorBlind.svg";
import MapPin2_CB from "../../images/map-pin2_colorBlind.svg";
import MapPin3_CB from "../../images/map-pin3_colorBlind.svg";
import MapPin4_CB from "../../images/map-pin4_colorBlind.svg";
import MapPin5_CB from "../../images/map-pin5_colorBlind.svg";
import MapPin6_CB from "../../images/map-pin6_colorBlind.svg";
import MapPin7_CB from "../../images/map-pin7_colorBlind.svg";
import MapPin8_CB from "../../images/map-pin8_colorBlind.svg";
import MapPin9_CB from "../../images/map-pin9_colorBlind.svg";
import TemplateUpdateModal from './templates/TemplateUpdateModal';

const ORIGIN_FIELD = 'ORIGIN'
const DESTINATION_FIELD = 'DESTINATIONS'
const DESTINATION_LAYER_ID = 'destinations-layer'
const MAP_PINS = [MapPin1, MapPin2, MapPin3,MapPin4, MapPin5, MapPin6, MapPin7, MapPin8, MapPin9, MapPin10]
const MAP_PINS_CB = [MapPin1_CB, MapPin2_CB, MapPin3_CB,MapPin4_CB, MapPin5_CB, MapPin6_CB, MapPin7_CB, MapPin8_CB, MapPin9_CB, MapPin10_CB]
const DARK_DISPLAY_MODE = 'dark'

moment.suppressDeprecationWarnings = true;

function TripModal({show, onHide, tripInfo, disabled, handleTripUpdate, listTemplates, isTemplateData, action, ...props}) {
  const isTripExist = (!tripInfo || isEmpty(tripInfo)) ? false : true
  const isTripActive = (tripInfo && tripInfo.isActive)

  const mapRef = useRef()
  const csvRef = useRef()
  const formRef = useRef()
  const prevoyageCsvRef = useRef()

  const vesselInfo = useSelector(state => state.user?.company?.vessels)
  const userInfo = useSelector(state => state.user?.company?.users)
  const colorBlind = useSelector(state => state.colorBlind)
  const unitMeasurement = useSelector(state => state.unitMeasurement)
  const displayMode = useSelector(state => state.displayMode)

  const [csvData, setCsvData] = useState([])  // decklogs csv
  const [prevoyageCsvData, setPrevoyageCsvData] = useState([])
  const [trafficLayer, setTrafficLayer] = useState({})
  const [mileMarkers, setMileMarkers] = useState([])
  const [destinationsData, setDestinations] = useState([])
  const [operatorList, setOperatorList] = useState([])
  const [selectedOperator, setSelectedOperator] = useState(null)
  const [operatorChanged, setOperatorChanged] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showEta, setShowEta] = useState(null)
  const [showDelete, setShowDelete] = useState(false)
  const [vesselList, setVesselList] = useState([])
  const [mapLayers, setMapLayers] = useState(null)
  const [disablePrevoyage, setDisablePrevoyage] = useState(true)
  const [templateId, setTemplateId] = useState("")
  const [isCorrectInputs, setIsCorrectInputs] = useState(true)
  const [showUpdateTemplate, setShowUpdateTemplate] = useState(false)
  
  const { 
    register, 
    reset, 
    control, 
    setValue,
    setError, 
    getValues, 
    clearErrors, 
    handleSubmit, 
    formState: { errors } 
  } = useForm({mode: "onChange"});

  useEffect(() => {
    if(isTripExist) {
      const getMileMarkers = async () => {
        try {
          const result = await getMilePath(props, {
            tripId: tripInfo.id
          })
          if(!result.getMilePath) return
          setMileMarkers(result.getMilePath)
        } catch (error) {
          console.log(error)
        }
      }
      getMileMarkers()
    }
    else {
      setMileMarkers([])
    }
    // Please do not remove the comment below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTripExist, tripInfo])

  useEffect(() => {
    if(prevoyageCsvData.length === 0 || !prevoyageCsvRef?.current) return
    prevoyageCsvRef.current.link.click()
  }, [prevoyageCsvData])

  // Check if prevoyage is available to download
  const checkDepartureTimeForPrevoyage = (date) => {
    let timeDiff = moment(date).diff(moment(), 'hours')
    if(timeDiff <= 1 && timeDiff >= 0) {
      setDisablePrevoyage(false)
      return
    }
    setDisablePrevoyage(true)
  }
  
  useEffect(() => {
    let sortedVesselList = [...vesselInfo]
    sortedVesselList = sortedVesselList.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
    sortedVesselList = sortedVesselList.sort((a,b) => (a.state > b.state) ? 1 : ((b.state > a.state) ? -1 : 0))

    sortedVesselList = sortedVesselList.map((vessel) => {
      let obj = Object.assign({}, vessel)
      obj.state = vessel.state.replace("_", " ")  // replace UNDER_REAPIR to UNDER REPAIR
      return obj
    })

    setVesselList(sortedVesselList)

    let operatorList = [...userInfo]
    
    operatorList = operatorList.filter(operator => operator.username !== "temp.form.login")
    operatorList.sort((a,b) => (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0))

    let tripInfoDestinations = tripInfo?.destinations ? [...tripInfo?.destinations] : []
    tripInfoDestinations = tripInfoDestinations.sort((a, b) => a.legNumber - b.legNumber)
    
    setOperatorList(operatorList)
    setDestinations(tripInfoDestinations)
    
    if(isTripExist && !isTemplateData) {
      setSelectedOperator(tripInfo.user)
    }

    let maxHeightFt = ""
    let maxHeightIn = ""
    let maxDraftFt = ""
    let maxDraftIn = ""
    if(tripInfo?.maxHeight) {
      maxHeightFt = tripInfo.maxHeight.toString().split('.')[0]
      maxHeightIn = Math.round((parseFloat(tripInfo.maxHeight) - parseInt(maxHeightFt)) * 12)
      if(unitMeasurement === 'm'){
        // ft to m
        maxHeightFt = Math.floor(ftTom(tripInfo.maxHeight))
        maxHeightIn = Math.round(tripInfo.maxHeight*30.48 - maxHeightFt*100)
      }
    }
    if(tripInfo?.maxDraft) {
      maxDraftFt = tripInfo.maxDraft.toString().split('.')[0]
      maxDraftIn = Math.round((parseFloat(tripInfo.maxDraft) - parseInt(maxDraftFt)) * 12)
      if(unitMeasurement === 'm'){
        // ft to m
        maxDraftFt = Math.floor(ftTom(tripInfo.maxDraft))
        maxDraftIn = Math.round(tripInfo.maxDraft*30.48 - maxDraftFt*100)
      }
    }
    if(tripInfo?.id) {
      setTemplateId(tripInfo.id)
    }

    // Initialize form input field values
    reset({
      name: tripInfo?.tripName ?? "",
      origin: tripInfo?.origin ?? {},
      destinations: tripInfoDestinations,
      departureDate: tripInfo?.departureDate ? moment(tripInfo.departureDate) : moment.now(),
      operator: tripInfo?.user ? [tripInfo.user] : null,
      vessel: tripInfo?.vessel ?? null,
      maxDraftFt,
      maxDraftIn,
      maxHeightFt,
      maxHeightIn
    })

    if(tripInfo) {
      checkDepartureTimeForPrevoyage(moment(tripInfo.departureDate))
    }
    
    if(tripInfo?.origin && tripInfo?.destinations.length > 0) {
      handleCalculateTrip()
    }
  
    if(!isTripExist) return
    setTrafficLayer({})
    setMileMarkers([])

    // Please do not remove the comment below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripInfo, isTripExist, reset, userInfo]) 

  const handleCalculateTrip = useCallback(async () => {
    let originData = getValues('origin')
    let getDestinations = getValues('destinations') ?? []
    let departureDate = getValues('departureDate')

    let hasError = false
    if(isEmpty(originData)) { hasError = true }
    if(getDestinations.length === 0) { hasError = true }

    if(hasError) {
      if(formRef?.current) {
        formRef.current.scroll({ top: 0, behavior: 'smooth'})
      }
      return
    }

    let destinationsId = getDestinations.map(d => {
      return d.destination.id
    })

    setLoading(true)
    try {
      const result = await calculateTrip(props, {
        origin: originData.id,
        destinations: destinationsId,
        departureDate: new Date(departureDate)
      })

      if(!result) {
        setLoading(false)
        setShowEta(null)
        errorToaster('Unable to calculate trip at the moment. Please try again later.')
        return
      }

      const addEtaTime = isTripActive ? moment.now() : departureDate
      let eta = calculateEtaHoursAndMinutes(addEtaTime, Math.round(result.calculateTrip.eta))

      setShowEta(eta)

      // Get Traffic
      let source = {
        id: "traffic_layer",
        layerName:'Polyline',
        geojson: {
          type: "FeatureCollection",
          features: [],
        },
        fillstyle: {
          id: "traffic-fill-layer",
          type: "fill",
          paint: {
            "fill-color": ["get", "color"],
            "fill-opacity": 0.3,
          },
        },
        style: {
          id: "traffic-outline-layer",
          type: "line",
          paint: {
            "line-color": ["get", "color"],
            "line-width": 2,
          },
        },
      }

      try {
        let mapTraffic = {
          type: "FeatureCollection",
          features: []
        }

        const result = await getTraffic(props, {
          origin: `POINT(${originData.geo.coordinates[0]} ${originData.geo.coordinates[1]})`,
          destinations: destinationsId
        })

        let urls = []
        for(let route of result.getTraffic) {
          urls.push(`${process.env.REACT_APP_JSON_CDN}${route.id}.json`)
        }
  
        await Promise.all(urls.map (url => 
          fetch(url)
          .then(response => response.json())
        ))
        .then(data => {
          

          // Update the color for each channel reach
          for(let index in result.getTraffic) {
            data[index].properties.color = result.getTraffic[index].color
            source.geojson.features = data
            mapTraffic.features.push(data[index])
          }
        })
        .catch(error => console.log(error))
  
        setTrafficLayer(mapTraffic)
      } catch (error) {
        errorToaster("Unable to fetch traffic at the moment. Please try again later.")
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      errorToaster("Route is invalid, cannot go upstream and downstream on the same river.")
      setLoading(false)
      setShowEta(null)
    }
  }, [getValues, props, isTripActive])

  // Initialize Map
  useEffect(() => {
    let view;
    loadModules([
      'esri/Map', 
      'esri/views/MapView', 
      "esri/layers/GeoJSONLayer",
      "esri/widgets/Home",
      'esri/widgets/LayerList',
      "esri/layers/GroupLayer",
      'esri/widgets/BasemapGallery',
      'esri/widgets/Expand',
      "esri/layers/WMSLayer"
    ])
      .then(([
        Map, 
        MapView, 
        GeoJSONLayer,
        Home,
        LayerList,
        GroupLayer,
        BasemapGallery,
        Expand,
        WMSLayer
      ]) => {
        if(!mapRef.current) return
        const map = new Map({ basemap: displayMode === DARK_DISPLAY_MODE ? 'dark-gray-vector' : 'gray-vector', });

        view = new MapView({
          container: mapRef.current,
          map: map,
          center: (isTripExist ? tripInfo.origin.geo.coordinates : [-87, 36]),
          zoom: 5
        });

        const basemapGallery = new BasemapGallery({ view: view });
        const homeWidget = new Home({ view: view });
        const layerList = new LayerList({ view: view })
        const expand = new Expand({
          view: view,
          content: basemapGallery,
          expanded: false
        });

        view.ui.add(layerList, 'top-left');
        view.ui.add(expand, 'top-right');
        view.ui.move("zoom", "bottom-right");
        view.ui.add(homeWidget, "bottom-right");

        const weatherRadarLayer = new WMSLayer({
          title: "Weather Radar",
          url: "https://mapservices.weather.noaa.gov/eventdriven/services/radar/radar_base_reflectivity/MapServer/WMSServer?request=GetCapabilities&service=WMS",
          listMode: "hide-children",
          visible: false
        })

        const waterwayLayer = new GeoJSONLayer({
          title: "Inland Waterway",
          url: process.env.REACT_APP_INLAND_WATERWAY,
          renderer: {
            type: "simple",
            symbol: {
              type: 'simple-marker',
              style: "circle",
              color: [100,149,237],
              size: 2,
              outline: {
                width: 0
              }
            }
          },
          visible: false
        })

        const IENCLayer = new WMSLayer({
          title: "IENC",
          url: "https://ienccloud.us/arcgis/services/IENC/USACE_IENC_Master_Service/MapServer/WMSServer?request=GetCapabilities&service=WMS",
          listMode: "hide-children",
          visible: false
        })

        const groupLayers = new GroupLayer({
          title: "Map Layers",
          visible: true,
          visibilityMode: "independent",
          layers: [
            IENCLayer,
            weatherRadarLayer, 
            waterwayLayer, 
          ],
          opacity: 0.75
        });

        map.add(groupLayers)
        setMapLayers(groupLayers)
        mapRef.current = view
      })
  }, [show, isTripExist, tripInfo, displayMode])

  // Origin, Destinations, and Traffic layers
  useEffect(() => {
    loadModules([
      'esri/layers/GraphicsLayer', 
      "esri/layers/GeoJSONLayer",
      'esri/Graphic'
    ])
      .then(([
        GraphicsLayer, 
        GeoJSONLayer,
        Graphic
      ]) => {
        if(!mapRef.current || isEmpty(trafficLayer) || !mapLayers) return

        // Traffic 
        if(!isEmpty(trafficLayer)) {
          const blob = new Blob([JSON.stringify(trafficLayer)], {
            type: "application/json"
          });
          const trafficLayerUrl = URL.createObjectURL(blob);
          const trafficLayerID = "traffic-layer"
          const checkTrafficLayer = mapLayers.layers.find(layer => layer.id === trafficLayerID)
          if(checkTrafficLayer) {
            mapLayers.layers.remove(checkTrafficLayer)
          }
          const tripTrafficLayer = new GeoJSONLayer({
            id: trafficLayerID,
            title: "Traffic",
            url: trafficLayerUrl,
            renderer: {
              type: 'unique-value',
              field: 'color',
              uniqueValueInfos: [{
                value: "#00ff00",
                symbol: {
                  type: 'simple-fill',
                  color: colorBlind? [0, 0, 255, 0.5]:[0, 255, 0, 0.5],
                  outline: {
                    color: colorBlind? [0,0,255]:[0, 255, 0],
                    width: 1.5
                  },
                },
              },
              {
                value: "#ffff00",
                symbol: {
                  type: 'simple-fill',
                  color: [255, 255, 0, 0.5],
                  outline: {
                    color: [255, 255, 0],
                    width: 1.5
                  },
                },
              },
              {
                value: "#ff0000",
                symbol: {
                  type: 'simple-fill',
                  color: [255, 0, 0, 0.5],
                  outline: {
                    color: [255, 0, 0],
                    width: 1.5
                  },
                },
              }
              ]
            },
          });
          mapLayers.add(tripTrafficLayer, -1)
        }

        // Mile Marker
        const mileMarkerLayer = new GraphicsLayer({
          id: "mile-marker-layer",
          title: "Mile Markers",
          visible: false
        })
        mileMarkers.forEach((point) => {
          const mileIcon = mileIcons(point.mile)
          const mileMarkerPoint = new Graphic({
            geometry: {
              type: 'point',
              longitude: point.long,
              latitude: point.lat
            },
            symbol: {
              type: "picture-marker",
              url: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(mileIcon)}`,
              width: "30px",
              height: "30px"
            }
          })
          mileMarkerLayer.add(mileMarkerPoint)
        })
        mapLayers.add(mileMarkerLayer)          

        
        // Current Position
        if(isTripActive) {
          const vesselPostiionLayer = new GraphicsLayer({ 
            title: "Current Position",
            graphics: {
              geometry: {
                type: "point",
                longitude: tripInfo.currentPosition.coordinates[0],
                latitude: tripInfo.currentPosition.coordinates[1],
              },
              symbol: {
                type: 'simple-marker',
                color: tripInfo.statuses.at(-1).status === 'PAUSE' ? "#CD2C2C" : (colorBlind? "#19168c":"#008F85"),
                size: '16px',
                outline: {
                  color: 'white',
                  width: 1
                }
              }
            }
          })
          mapLayers.add(vesselPostiionLayer)
        }

        if(isTripExist) {
          // Origin
          const originLayer = new GraphicsLayer({ 
            title: "Origin",
            graphics: {
              geometry: {
                type: 'point',
                longitude: tripInfo.origin.geo.coordinates[0],
                latitude: tripInfo.origin.geo.coordinates[1],
              },
              symbol: {
                type: "picture-marker",
                url: OriginPin,
                width: "24px",
                height: "24px"
              }
            }
          })
          mapLayers.add(originLayer)
  
          redrawDestinationsLayer(getValues('destinations'))
        }
      })

      // Please do not remove the comment below
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trafficLayer, tripInfo, isTripExist, mileMarkers, mapLayers, getValues])

  const handleSearchLocationOnClick = (location, type, destIndex) => {
    if(mapRef?.current) {
      mapRef.current.goTo({
        center: location.geo.coordinates,
        zoom: 8,
        duration: 2000,
        easing: 'ease-in-out'
      })
    }

    switch(type) {
      case ORIGIN_FIELD:
        setValue('origin', location)
        loadModules(['esri/layers/GraphicsLayer']).then(([GraphicsLayer]) => {
          const originID = 'origin-layer'
          const checkOriginLayer = mapLayers.layers.find(layer => layer.id === originID)
          if(checkOriginLayer) {
            mapLayers.layers.remove(checkOriginLayer)
          }
          const originLayer = new GraphicsLayer({ 
            id: originID,
            title: "Origin",
            graphics: {
              geometry: {
                type: 'point',
                longitude: location.geo.coordinates[0],
                latitude: location.geo.coordinates[1],
              },
              symbol: {
                type: "picture-marker",
                url: OriginPin,
                width: "24px",
                height: "24px"
              }
            }
          })
          mapLayers.add(originLayer)
        })
        break;
      case DESTINATION_FIELD:
        let destArr = getValues('destinations') ?? []
        destArr[destIndex] = {
          destination: location,
          legNumber: destIndex + 1
        }
        setValue('destinations', destArr)
        setDestinations(destArr)
        redrawDestinationsLayer(destArr)
        break;
      default:
        break;
    }

    setTrafficLayer({})
    setMileMarkers([])
    if(getValues('origin') && getValues('destinations')) {
      handleCalculateTrip()
    }
  }

  const redrawDestinationsLayer = (destArr) => {
    loadModules(['esri/layers/GraphicsLayer', 'esri/Graphic'])
    .then(([GraphicsLayer, Graphic]) => {
      const checkDestsLayer = mapLayers.layers.find(layer => layer.id === DESTINATION_LAYER_ID)
      if(checkDestsLayer) {
        mapLayers.layers.remove(checkDestsLayer)
      }
      const destinationsLayer = new GraphicsLayer({title: "Destinations", id: DESTINATION_LAYER_ID})
      destArr.forEach((point, i) => {
        const destinationPoint = new Graphic({
          geometry: {
            type: "point",
            longitude: point.destination.geo.coordinates[0],
            latitude: point.destination.geo.coordinates[1],
          },
          symbol: {
            type: "picture-marker",
            url: colorBlind? MAP_PINS_CB[i]:MAP_PINS[i],
            width: "24px",
            height: "24px"
          }
        })
        destinationsLayer.add(destinationPoint)
      })
      mapLayers.add(destinationsLayer)
    })
  }

  const onDragEnd = e => {
    let destArr = getValues('destinations')
    if(destArr.length === 0) return

    // need a little delay to get the destinations value
    // swap the destination order
    [destArr[e.source.index], destArr[e.destination.index]] = [destArr[e.destination.index], destArr[e.source.index]]

    // re-assign the leg number for each destination
    for(let index in destArr) {
      destArr[index].legNumber = parseInt(index) + 1
    }

    setValue('destinations', destArr)
    setDestinations(destArr)
    redrawDestinationsLayer(destArr)
    handleCalculateTrip()
  }

  const removeDestination = (destIndex) => {
    let destArr = getValues('destinations')
    
    destArr.splice(destIndex, 1)

    // re-assign the leg number for each destination
    for(let index in destArr) {
      destArr[index].legNumber = parseInt(index) + 1
    }

    setValue('destinations', destArr)
    setDestinations(destArr)
    redrawDestinationsLayer(destArr)
    setTrafficLayer({})
    setMileMarkers([])
    handleCalculateTrip()
  }

  const downloadDecklogs = async () => {
    try {
      let result = await getCsvDecklogs(props, {
        tripId: tripInfo.id
      })

      if(!result.getDecklogs) {
        errorToaster("Decklog is currently unavailable. Please try again later.")
        return;
      }

      let csv = downloadCSV(result.getDecklogs, tripInfo.origin, tripInfo.destinations)
      csv = [
        csv.locationHeader,
        ...csv.locationData,
        [],
        csv.alertHeader,
        ...csv.data
      ]
      setCsvData(csv)

      // Set a little delay so data can get pass into the CSVLink tag
      if(csvRef?.current) {
        setTimeout(() => {
          csvRef.current.link.click()
        }, 150);
      }
    } catch (error) {
      errorToaster("Unable to download deck logs at the moment. Please try again later.")
    }
  }
  
  const downloadPrevoyage = async () => {
    if(disablePrevoyage) {
      warningToaster("Prevoyage is not available at this moment. It'll be available one hour prior to the departure time.")
      return
    }

    try {
      const result = await getCsvPrevoyage(props, {
        tripId: tripInfo.id
      })
      if(!result.getPrevoyagePlan) {
        errorToaster("Prevoyage is currently unavailable. Please try again later.")
        return;
      }

      let csv = downloadCSV(result.getPrevoyagePlan, tripInfo.origin, tripInfo.destinations)
      csv = [
        csv.locationHeader,
        ...csv.locationData,
        [],
        csv.alertHeader,
        ...csv.data
      ]
      setPrevoyageCsvData(csv)

    } catch (error) {
      errorToaster("Unable to download prevoyage at the moment. Please try again later.")
    }
  }

  const removeErrors = name => {
    clearErrors(name)
  }

  const templateButtonOnClick = () => {
    if(templateId === "") {
      saveAsTemplate()
    }
    else {
      setShowUpdateTemplate(true)
    }
  }

  const saveAsTemplate = async () => {
    setShowUpdateTemplate(false)
    let data = getValues()

    let pass = true
    if(data.name === "") {setError('name', {message: "Please enter a trip name"}); pass = false}
    if(isEmpty(data.origin)) {setError('origin', {message: "Please select an origin"}); pass = false}
    if(data.destinations.length === 0) {setError('destinations', {message: "Please select a destination"}); pass = false}
    if(!data.vessel) {setError('vessel', {message: "Please select a vessel"}); pass = false}

    let maxDraft = ""
    if(data.maxDraftFt !== "") {
      let draftInToDec = parseInt(data.maxDraftIn === "" ? 0 : data.maxDraftIn) / 12
      maxDraft = parseFloat((parseInt(data.maxDraftFt) + draftInToDec).toFixed(2))
      data.maxDraft = maxDraft
    }

    let maxHeight = ""
    if(data.maxHeightFt !== "") {
      let heightInToDec = parseInt(data.maxHeightIn === "" ? 0 : data.maxHeightIn) / 12
      maxHeight = parseFloat((parseInt(data.maxHeightFt) + heightInToDec).toFixed(2))
      data.maxHeight = maxHeight
    }
    
    if(!pass) return
    
    let destinationsID = data.destinations.map((d) => {
      return {
        locationId: d.destination.id,
        legNumber: d.legNumber
      }
    })

    // Re-structure
    let templateData = {
      templateName: data.name,
      originId: data.origin.id,
      destinations: destinationsID,
      vesselId: data.vessel.id,
      maxHeight: data.maxHeight,
      maxDraft: data.maxDraft
    }

    try {
      const result = await createTemplate(props, {...templateData})
      if(!result?.createTripTemplate?.tripTemplate) {
        setTemplateId("")
        errorToaster("Unable to save template at the moment. Please try again later.")
        return
      }
      setTemplateId(result.createTripTemplate.tripTemplate.id)
      successToaster("Template saved!")
    } catch (error) {
      setTemplateId("")
      errorToaster("Unable to save template at the moment. Please try again later.")
      console.log(error)
    }
  }
  
  const inputOnChange = e => {
    let inputName = e.target.name
    let inputVal = e.target.value
    let regExp = /[a-zA-Z]/g;

    switch(inputName) {
      case "name":
        if(inputVal === "") {
          setError(inputName, {message: "Please enter a trip name"})
        }
        else {clearErrors(inputName)}
        break;
      case "origin":
      case "destinations":
        if(inputVal === "") {
          setError(inputName, {message: "Please select a location"})
        }
        else {clearErrors(inputName)}
        break;
      case "maxDraftFt":
        if(regExp.test(inputVal)){
          errorToaster("Please enter number only.")
          setError(inputName, {message: "Please enter number only"})
        }
        else if(unitMeasurement === 'ft' && inputVal && ((inputVal < 0 || inputVal > 12) || (inputVal % 1 !== 0))) {
          errorToaster("Max draft can only be between 0-12 ft.")
          setError(inputName, {message: "Max draft (ft) can only be between 0 to 12 ft"})
        }
        else if (unitMeasurement === 'm' && inputVal && ((inputVal < 0 || inputVal > 3) || (inputVal % 1 !== 0))){
          errorToaster("Max draft can only be between 0-3 m.")
          setError(inputName, {message: "Max draft (m) can only be between 0 to 3 m"})
        }
        else {clearErrors(inputName)}
        break;
      case "maxDraftIn":
        if(regExp.test(inputVal)){
          errorToaster("Please enter number only.")
          setError(inputName, {message: "Please enter number only"})
        }
        else if(unitMeasurement === 'ft' && inputVal && ((inputVal < 0 || inputVal > 12) || (inputVal % 1 !== 0))){
          errorToaster("Please enter number between 0 to 12 in.")
          setError(inputName, {message: "Max draft can only be between 0 to 12 in"})
        }
        else if(unitMeasurement === 'm' && inputVal && ((inputVal < 0 || inputVal > 30) || (inputVal % 1 !== 0))){
          errorToaster("Please enter number between 0 to 30 cm.")
          setError(inputName, {message: "Max draft can only be between 0 to 30 cm"})
        }
        else {clearErrors(inputName)}
        break;
      case "maxHeightFt":
        if(regExp.test(inputVal)){
          errorToaster("Please enter number only.")
          setError(inputName, {message: "Please enter number only"})
        }
        else if(unitMeasurement === 'ft' && inputVal && ((inputVal < 1 || inputVal > 100) || (inputVal % 1 !== 0))) {
          errorToaster("Max height can only be between 1 to 100 ft.")
          setError(inputName, {message: "Max height can only be between 0 to 100 ft"})
        }
        else if (unitMeasurement === 'm' && inputVal && ((inputVal < 1 || inputVal > 30) || (inputVal % 1 !== 0))) {
          errorToaster("Max height can only be between 1 to 30 m.")
          setError(inputName, {message: "Max height can only be between 0 to 30 m"})
        }
        else {clearErrors(inputName)}
        break;
      case "maxHeightIn":
        if(regExp.test(inputVal)){
          errorToaster("Please enter number only.")
          setError(inputName, {message: "Please enter number only"})
        }
        else if(unitMeasurement === 'ft' && inputVal && ((inputVal < 0 || inputVal > 12) || (inputVal % 1 !== 0))) {
          errorToaster("Please enter number between 0 to 12 in.")
          setError(inputName, {message: "Max height can only be between 0 to 12 in"})
        }
        else if(unitMeasurement === 'm' && inputVal && ((inputVal < 0 || inputVal > 30) || (inputVal % 1 !== 0))){
          errorToaster("Please enter number between 0 to 30 cm.")
          setError(inputName, {message: "Max height can only be between 0 to 30 cm"})
        }
        else {clearErrors(inputName)}
        break;
      default:
        break;
    }
    setIsCorrectInputs(Object.keys(errors).length === 0)
  }

  const onSubmit = async (data) => {
    // max draft
    let draftInToDec
    let maxDraft
    if(unitMeasurement === 'ft'){
      draftInToDec = parseInt(data.maxDraftIn === "" ? 0 : data.maxDraftIn) / 12
      maxDraft = parseFloat((parseInt(data.maxDraftFt) + draftInToDec).toFixed(2))
    }else{
      // convert from m to ft
      draftInToDec = parseInt(data.maxDraftIn === "" ? 0 : data.maxDraftIn)
      maxDraft = (parseInt(data.maxDraftFt) + (draftInToDec/100))
      maxDraft = parseFloat((maxDraft * 3.2808399).toFixed(2))
    }
    
    // max height
    let heightInToDec
    let maxHeight
    if(unitMeasurement === 'ft'){
      heightInToDec = parseInt(data.maxHeightIn === "" ? 0 : data.maxHeightIn) / 12
      maxHeight = parseFloat((parseInt(data.maxHeightFt) + heightInToDec).toFixed(2))
    }else{
      // convert from m to ft
      draftInToDec = parseInt(data.maxHeightIn === "" ? 0 : data.maxHeightIn)
      maxHeight = (parseInt(data.maxHeightFt) + (draftInToDec/100))
      maxHeight = parseFloat((maxHeight * 3.2808399).toFixed(2))
    }

    let tripData = {
      name: data.name,
      origin: data.origin.id,
      destinations: data.destinations.map(d => {
        return d.destination.id
      }),
      vesselId: data.vessel.id,
      departureDate: new Date(data.departureDate),
      maxDraft,
      maxHeight
    }

    if(data.operator?.length > 0) {
      // need to adjust this when backend supports multi operators
      tripData['user'] = data.operator.id 
    }

    setLoading(true)
    if(isTripExist && !isTemplateData) { // edit trip
      try {
        const result = await editTrip(props, {
          ...tripData,
          id: tripInfo.id
        })
        checkDepartureTimeForPrevoyage(result.editTrip.departureDate)
        handleTripUpdate(result.editTrip.trip, 'edit')
      } catch (error) {
        errorToaster("Unable to edit the trip at the moment. Please try again later.")
      }
    }
    else { // add trip
      try {
        const result = await addTrip(props, tripData)
        checkDepartureTimeForPrevoyage(result.createTrip.departureDate)
        handleTripUpdate(result.createTrip.trip, 'add')
      } catch (error) {
        errorToaster("Unable to create a trip at the moment. Please try again later.")
      }
    }
    setLoading(false)
  }

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
      size={"xl"}
      className={displayMode === DARK_DISPLAY_MODE? "darkmode" : ''}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className='d-flex justify-content-center align-items-center'>
            {(isTripExist && !isTemplateData) ? `Edit trip - ${tripInfo.tripName}` : "Add a new trip"}
            
            {(isTripExist && !isTemplateData) ?
              <Button
                className='ms-3'
                variant='contained'
                color="danger"
                onClick={() => setShowDelete(true)}
              >
                <Trash2 size={20}/>
              </Button>
              :
              <Button
                className='ms-3'
                variant='contained'
                onClick={listTemplates}
              >
                Use template 
              </Button>
            }
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showDelete &&
          <DeleteModal 
            show={showDelete}
            onHide={() => setShowDelete(false)}
            name={tripInfo.tripName}
            id={tripInfo.id}
            tripInfo={tripInfo}
            handleTripUpdate={handleTripUpdate}
            deleteType="TRIP"
          />
        }

        {showUpdateTemplate &&
          <TemplateUpdateModal 
            show={showUpdateTemplate}
            onHide={() => setShowUpdateTemplate(false)}
            tripInfo={getValues()}
            id={tripInfo.id}
            onSaveAs={saveAsTemplate}
          />
        }

        {loading &&
          <img className='loading absolute' src={LoadingIcon} alt="Loading..." />
        }

        <div 
          className='center-y trip-modal'
          style={{
            opacity: loading ? 0.6 : 1,
            pointerEvents: loading ? 'none' : 'auto'
          }}
        >
          <div 
            ref={mapRef}
            style={{
              width: '70%', 
              height: '100%',
              minHeight: 400,
              borderRadius: 10
            }}
          />

          <div 
            className={'trip-modal-inputs ' + (showEta ? "eta" : "")}
            ref={formRef}
          >

            <form className='trip-modal-form' style={{position: 'relative'}} onSubmit={handleSubmit(onSubmit)}>
              <TextField
                {...register("name", {required: "Please enter a trip name"})}
                label="Trip Name"
                variant="standard"
                fullWidth={true}
                disabled={disabled || isTripActive}
                {...register("name", { required: "Please enter a trip name" })}
                error={!!errors.name}
                helperText={errors?.name?.message}
                autoComplete='off'
              />

              <LocationSearch 
                {...register('origin', {required: "Please select an origin"})} 
                error={!!errors.origin}
                helperText={errors?.origin?.message}
                handleSearchLocationOnClick={handleSearchLocationOnClick} 
                type={ORIGIN_FIELD}
                clearErrors={removeErrors}
                locationName={tripInfo?.origin}
                disabled={disabled || isTripActive}
              />

              <DraggableInputs 
                existingDestinations={destinationsData} 
                error={!!errors.destinations}
                helperText={errors?.destinations?.message}
                handleSearchLocationOnClick={handleSearchLocationOnClick}
                removeDestination={removeDestination}
                clearErrors={removeErrors}
                onDragEnd={onDragEnd}
                register={{...register('destinations', {required: "Please select a destination"})}}
                disabled={disabled}
              />

              <Controller
                control={control}
                name="departureDate"
                rules={{ required: true }}
                render={({field: {onChange, value}}) => (
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                      className='trip-modal-text-field'
                      label="Departure Date (MM/DD/YYYY | HH:mm)"
                      variant="standard"
                      value={value}
                      inputFormat="MM/DD/YYYY | HH:mm"
                      ampm={false}
                      disabled={disabled || isTripActive}
                      name="departureDate"
                      onChange={onChange}
                      renderInput={(params) => <TextField 
                        {...params}
                        variant="standard" 
                        fullWidth={true}/>
                      }
                    />
                  </LocalizationProvider>
                )}
              />

              <Controller
                control={control}
                name="operator"
                render={({field: {onChange, value}}) => (
                  <Autocomplete
                    // multiple
                    className='trip-modal-text-field'
                    options={operatorList}
                    getOptionLabel={(option) => option.firstName && option.lastName ? `${option.firstName} ${option.lastName} (${option.username})` : `(${option.username})`}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    disabled={disabled}
                    onChange={(_, val) => {
                      onChange(val)
                      setOperatorChanged(true)
                    }}
                    value={!operatorChanged ? selectedOperator : value}
                    renderInput={(params) => 
                      <TextField 
                        {...params} 
                        label="Select An Operator" 
                        variant="standard" 
                        error={!!errors.operator}
                        helperText={errors?.operator?.message}
                      />
                    }
                  />
                )}
              />

              <Controller
                control={control}
                name="vessel"
                rules={{ required: true }}
                render={({field: {onChange, value}}) => (
                  <Autocomplete
                    className='trip-modal-text-field'
                    disableClearable
                    options={vesselList}
                    getOptionLabel={(option) => option.name && option.mmsi ? `${option.name} (${option.mmsi})` : ''}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    groupBy={(option) => option.state}
                    disabled={disabled || isTripActive}
                    onChange={(_, val) => {
                      onChange(val)
                    }}
                    value={value ?? null}
                    renderInput={(params) => 
                      <TextField 
                        {...params} 
                        label="Select a Vessel" 
                        variant="standard" 
                        error={!!errors.vessel}
                        helperText={errors?.vessel?.message}
                        required
                      />
                    }
                  />
                )}
              />

              <div className='d-flex justify-content-between'>
                <TextField
                  className='trip-modal-text-field'
                  label="Max Draft"
                  variant="standard"
                  type="number"
                  autoComplete='off'
                  disabled={disabled}
                  {...register("maxDraftFt", { required: "Please enter a max draft value" })}
                  error={!!errors.maxDraftFt}
                  helperText={errors?.maxDraftFt?.message}
                  onChange={inputOnChange}
                  InputProps={{
                    min: 0,
                    max: unitMeasurement==='ft'? 12: 3,
                    step: 1,
                    endAdornment: 
                      <InputAdornment position="end">
                        <span>
                          {
                            unitMeasurement==='ft'?<b>ft.</b>:<b>m.</b>
                          }
                        </span>
                      </InputAdornment>
                  }}
                  sx={{width: "45%"}}
                />
                <TextField
                  className='trip-modal-text-field'
                  label="Max Draft"
                  variant="standard"
                  fullWidth={true}
                  type="number"
                  autoComplete='off'
                  disabled={disabled}
                  {...register("maxDraftIn", { required: "Please enter a max draft value" })}
                  error={!!errors.maxDraftIn}
                  helperText={errors?.maxDraftIn?.message}
                  onChange={inputOnChange}
                  InputProps={{
                    min: 0,
                    max: unitMeasurement==='ft'? 12: 30,
                    step: 1,
                    endAdornment: 
                      <InputAdornment position="end">
                        <span>
                          {
                            unitMeasurement==='ft'?<b>in.</b>:<b>cm.</b>
                          }
                        </span>
                      </InputAdornment>
                  }}
                  sx={{width: "45%"}}
                />

              </div>

              <div className='d-flex justify-content-between'>
                <TextField
                  className='trip-modal-text-field'
                  label="Max Height"
                  variant="standard"
                  type="number"
                  autoComplete='off'
                  disabled={disabled}
                  {...register("maxHeightFt", { required: "Please enter a max height value" })}
                  error={!!errors.maxHeightFt}
                  helperText={errors?.maxHeightFt?.message}
                  onChange={inputOnChange}
                  InputProps={{
                    endAdornment: 
                    <InputAdornment position="end">
                      <span>
                        {
                          unitMeasurement==='ft'?<b>ft.</b>:<b>m.</b>
                        }
                      </span>
                    </InputAdornment>
                  }}
                  sx={{width: "45%"}}
                />
                <TextField
                  className='trip-modal-text-field'
                  label="Max Height"
                  variant="standard"
                  type="number"
                  autoComplete='off'
                  disabled={disabled}
                  {...register("maxHeightIn")}
                  error={!!errors.maxHeightIn}
                  helperText={errors?.maxHeightIn?.message}
                  onChange={inputOnChange}
                  InputProps={{
                    endAdornment: 
                      <InputAdornment position="end">
                        <span>
                          {
                            unitMeasurement==='ft'?<b>in.</b>:<b>cm.</b>
                          }
                        </span>
                      </InputAdornment>
                  }}
                  sx={{width: "45%"}}
                />
              </div>

              {disabled ? 
                <>
                  <Button
                    className='mt-3'
                    variant='outlined'
                    fullWidth
                    size='large'
                    onClick={downloadDecklogs}
                  >
                    <b>Download Deck Logs</b>
                  </Button>
                  <CSVLink 
                    ref={csvRef}
                    data={csvData}
                    filename={`RippleGo_Decklogs_${tripInfo.tripName}_${moment(tripInfo.departureDate).format("YYYY-MM-DDTHHmm")}.csv`}
                  />
                </>
                :

                <div className='pt-3 pb-4'>
                  { action !== "EDIT" &&
                    <Button
                      variant='outlined'
                      fullWidth
                      size='large'
                      onClick={templateButtonOnClick}
                    >
                      <b>{(templateId === "") ? "Save as template" : "Update Template" }</b>
                    </Button>
                  }

                  <Button
                    className='mt-2'
                    variant='outlined'
                    fullWidth
                    size='large'
                    type='submit'
                    disabled={showEta ? !isCorrectInputs : true}
                  >
                    <b>{(isTripExist && !isTemplateData) ? "Update Trip" : "Create Trip"}</b>
                  </Button>
                  
                  {showEta &&
                    <Button
                      id={disablePrevoyage ? 'gray-out': ""}
                      className='mt-2'
                      variant='outlined'
                      fullWidth
                      size='large'
                      onClick={downloadPrevoyage}
                    >
                      <b>Download Prevoyage</b>
                    </Button>
                  }
                  {
                    prevoyageCsvData.length > 0 &&
                    <CSVLink 
                      ref={prevoyageCsvRef}
                      data={prevoyageCsvData}
                      filename={`RippleGo_Prevoyage_${tripInfo.tripName}_${moment(tripInfo.departureDate).format("YYYY-MM-DDTHHmm")}.csv`}
                    />
                  }
                </div>
              }
            </form>

            {showEta &&
              <div className={'trip-modal-eta ' + (colorBlind? "color-blind-eta":"")}>
                <div>
                  {getValues('origin').city ?? getValues('origin').name}
                  {" "}to{" "}
                  {destinationsData.at(-1).destination.city?.toUpperCase() ?? destinationsData.at(-1).destination.name?.toUpperCase()}:
                </div>
                <div>ETA: {showEta}</div>
              </div>
            }
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default withApollo(TripModal)