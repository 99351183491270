import { withApollo } from '@apollo/client/react/hoc';
import { faAward, faShip, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from "react-redux";
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import Table from '../components/table/Table';
import LoadingIcon from "../images/boat.gif";
import { fetchAllCompanies } from '../services';

const ROLE_DISPATCHER = "DISPATCHER"
const ROLE_OPERATOR = "OPERATOR"
const TABLE_COLUMNS = [
  {
    Header: "Company Name",
    accessor: 'name'
  },
  {
    Header: "# of Dispatchers",
    accessor: "dispatchers"
  },
  {
    Header: "# of Operators",
    accessor: "operators"
  },
  {
    Header: "# of Vessels",
    accessor: "vessels"
  },
  {
    Header: "Subscription Tier",
    accessor: "sub"
  }
]

function Company(props) {
  const companyInfo = useSelector(state => state.user?.company)
  const superAdmin = useSelector(state => state.superAdmin)

  const [numDispatchers, setNumDispatchers] = useState(0)
  const [numOperators, setNumOperators] = useState(0)
  const [numVessels, setNumVessels] = useState(0)
  const [companyList, setCompanyList] = useState([])
  const [loading, setLoading] = useState(true)

  const tableColumns = useMemo(() => TABLE_COLUMNS, []) 
  const tableData = useMemo(() => companyList, [companyList])

  const colorBlind = useSelector(state => state.colorBlind)
  const displayMode = useSelector(state => state.displayMode)
  const DARK_DISPLAY_MODE = 'dark'

  useEffect(() => {
    document.title = 'RippleGo Dispatcher | Company'

    const getCompanyInfo = async () => {
      let numVessels = companyInfo.vessels.filter(v => v.isActive).length
      setNumVessels(numVessels)

      let numOperators = companyInfo.users.filter(user => user.role === ROLE_OPERATOR).length
      let numDispatchers = companyInfo.users.filter(user => user.role === ROLE_DISPATCHER).length
      setNumDispatchers(numDispatchers)
      setNumOperators(numOperators)
    }

    const getAllCompaniesInfo = async () => {
      setLoading(true)
      try {
        const result = await fetchAllCompanies(props) 
        if(!result.getCompanies) return

        let companyList = []
        for(let company of result.getCompanies) {
          if(!company) continue
          companyList.push({
            name: company.name,
            dispatchers: company.users.filter(user => user.role === "DISPATCHER").length,
            operators: company.users.filter(user => user.role === "OPERATOR").length,
            vessels: company.vessels.filter(vessel => vessel.isActive).length,
            sub: company.name === "Trabus Technologies" ? "Unlimited": "Free Trial"
          })
        }
        companyList = companyList.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        setCompanyList(companyList)
      } catch (error) {
        console.log(error)
      }
    }

    if(superAdmin) {
      getAllCompaniesInfo()
    }
    else {
      getCompanyInfo()
    }
    setLoading(false)

    // Please do not remove the comment below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {loading &&
        <img className='loading' style={{marginTop: '20%'}} src={LoadingIcon} alt="Loading..." />
      }
      <div style={{display: ((superAdmin && !loading) ? "initial" : "none")}}>
        <h2>
          Company List
        </h2>
        <div 
          style={{
            color : displayMode === DARK_DISPLAY_MODE? "white" : '#000A18',
            background: displayMode === DARK_DISPLAY_MODE? "#2F2F2F" : 'white'
          }}
          className='card shadow'
        >
          <div className='card-body'>
            <Table
              tableProps={
                useTable(
                  {
                    columns: tableColumns, 
                    data: tableData,
                    stateReducer: (newState, action) => {
                      if(action.type === 'toggleRowSelected') {
                        newState.selectedRowIds = {
                          [action.id]: true
                        }
                      }
                      else {
                        newState.selectedRowIds = {}
                      }
                      return newState
                    }
                  },
                  useGlobalFilter,
                  useSortBy,
                  usePagination,
                  useRowSelect,
              )}
            />
          </div>
        </div>
      </div>
      <div style={{display: (superAdmin ? "none" : "initial")}}>
        <h2>
          {companyInfo?.name ? companyInfo.name : 'Company'}
        </h2>
        <div className='summary-cards'>
          <div className={'card-detail ' + (colorBlind? "color-blind-card" : "")}>
            <div>
                <h6>Dispatcher</h6>
                <h4>{numDispatchers}</h4>
            </div>
            <FontAwesomeIcon className='card-icon' icon={faUserGroup} />
          </div>

          <div className={'card-detail ' + (colorBlind? "color-blind-card" : "")}>
            <div>
                <h6>Operators</h6>
                <h4>{numOperators}</h4>
            </div>
            <FontAwesomeIcon className='card-icon' icon={faUserGroup} />
          </div>

          <div className={'card-detail ' + (colorBlind? "color-blind-card" : "")}>
            <div>
                <h6>Vessels</h6>
                <h4>{numVessels}</h4>
            </div>
            <FontAwesomeIcon className='card-icon' icon={faShip} />
          </div>

          <div className={'card-detail ' + (colorBlind? "color-blind-card" : "")}>
            <div>
                <h6>Subscription Tier</h6>
                <h4>Diamond</h4>
            </div>
            <FontAwesomeIcon className='card-icon' icon={faAward}/>
          </div>
        </div>
      </div>
    </>
  )
}

export default withApollo(Company)