import { reactLocalStorage } from "reactjs-localstorage";
import { logoutSession } from "../store/action";
import history from "./history";
import { store } from "../store";
import { errorToaster, successToaster } from "./messageToast";
import moment from "moment";
import _ from "lodash"
import { isEmpty } from "lodash";

export const logout = (props) => {
  if (props) {
    props.dispatch(logoutSession(false));
  } else {
    store.dispatch(logoutSession(false));
  }
  store.dispatch({
    type: "RESET",
  });
  reactLocalStorage.remove("token");
  reactLocalStorage.remove("refreshToken");
  reactLocalStorage.clear();
  localStorage.setItem("persist:root", null);
  history.push("/login");
};

export const checkVesselAvailability = data => {
  let available = 0, inUse = 0, repair = 0;

  for(let vessel of data) {
    if(vessel.state === 'AVAILABLE') {
      available++
    }
    else if(vessel.state === 'UNAVAILABLE') {
      inUse++
    }
    else {
      repair++
    }
  }

  return {
    available,
    inUse,
    repair
  }
}

export const calculateEtaHoursAndMinutes = (newDate, eta) => {
  let currentDate = moment(newDate).add(eta, "minutes").toDate();
  return `${moment(currentDate).format("MMM DD, YYYY HH:mm")}`;
};


export const errorHandler = (error, type, props) => {
  try {
    console.log(error);
    if (error.graphQLErrors[0].code === 400) {
      let details = error.graphQLErrors[0].details;
      let errors = [];
      for (const key in details) {
        errors = errors.concat(details[key]);
      }
      errorToaster(errors[0]);
      console.log('error1: ',errors[0])
    }
    if (error.graphQLErrors[0].code === 500) {
      let details = error.graphQLErrors[0].details;
      let errors = [];
      if(type === 'refreshToken') {
        logout(props)
      }
      if (typeof details === "string") {
        console.log('error2: ',details)
        return errorToaster(details);
      }
      for (const key in details) {
        errors = errors.concat(details[key]);
      }
      errorToaster(errors[0]);
      console.log('error3: ',errors[0])
    }
  } catch {
    errorToaster("Server Error. Erro H:373");
  }
};

export const findOverlappingDateRanges = dateRanges => {
  const sortedRanges = dateRanges.map((range, index) => ({ ...range, index })).sort((a, b) => a.sd - b.sd);
  const overlappingIndexes = [];

  for (let i = 1; i < sortedRanges.length; i++) {
    if (sortedRanges[i].sd < sortedRanges[i - 1].ed) {
      overlappingIndexes.push(sortedRanges[i - 1].index, sortedRanges[i].index);
    }
  }

  return overlappingIndexes;
}

export const checkEndDate = (destinations, departureDate) => {
  let end_date = moment(moment.now()).add(destinations.at(-1).eta, 'minutes')
  
  // if it's a future trip, set end date to departure date + eta
  if(new Date(departureDate).getTime() > new Date().getTime()) {
      end_date = moment(departureDate).add(destinations.at(-1).eta, 'minutes')
  }

  if(destinations.at(-1).arrivedAt) {
      end_date = moment(destinations.at(-1).arrivedAt)
  }
  
  return end_date
}

export const addEditDeleteTrip = (trip, allTrips, type) => {
  let trips = [...allTrips]

  switch(type) {
    case 'add':
      trips.unshift(trip)
      successToaster("Trip created successfully!")
      break;
    case 'edit':
      let editTripIndex = trips.findIndex(data => data.id === trip.id )
      trips[editTripIndex] = trip
      successToaster("Trip updated successfully!")
      break;
    case 'delete':
      _.remove(trips, {id: trip.id})
      successToaster("Trip deleted successfully!")
      break;
    default:
      break;
  }

  return trips
}


export const downloadCSV = (CSVData, origin, destinations) => {
  let updatedCSVData = []
  let locationHeader = ['Location', 'Name']
  let alertHeader = []

  // remove __typename and re-format date time
  for(let data of CSVData) {
    let row = []
    for(let [key,val] of Object.entries(data)) {
      if(key === "__typename") continue
      if(key === 'datetime' && val !== "-") {
        val = moment(val).format('YYYY/MM/DD  HH:mmZ')
      }
      if(key === 'description' && val.includes('RESUME')) {
        val = 'RESUME'
      }
      if(key === 'description' && val.includes('END')) {
        val = 'END'
      }

      if(!alertHeader.includes(csvHeaderNames(key))) {
        alertHeader.push(csvHeaderNames(key))
      }
      row.push(val)
    }
    updatedCSVData.push(row)
  }

  // add origin and destinations
  if(isEmpty(origin) || destinations.length === 0) return updatedCSVData

  let locationCSVData = []
  destinations = destinations.reverse()
  
  for(let i in destinations) {
    locationCSVData.unshift([`DESTINATION ${(parseInt(destinations.length - i))}`, `${destinations[i].destination.name.toUpperCase()}, ${destinations[i].destination.city?.toUpperCase() ?? ""}`])
  }
  locationCSVData.unshift(["ORIGIN", `${origin.name.toUpperCase()}, ${origin.city?.toUpperCase() ?? ""}`])

  return {locationHeader, alertHeader, data: updatedCSVData, locationData: locationCSVData}
}

const csvHeaderNames = (name) => {
  switch(name) {
    case "datetime":
      return "Datetime"
    case "lat":
      return "Latitude"
    case "long":
      return "Longitude"
    case "eta":
      return "ETA (minutes)"
    case "alertType":
      return "Alert Type"
    default: 
      return name.charAt(0).toUpperCase() + name.slice(1);
  }
}

export const ftTom = (num) => {
  return num*0.3048
}