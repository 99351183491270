import React, { useEffect } from 'react'
import UnderConstruction from "../images/under_construction.gif"

export default function MessageCenter() {

  useEffect(() => {
    document.title = 'RippleGo Dispatcher | Message Center'
  }, [])

  return (
    <div>
      <img className='loading' src={UnderConstruction} alt="Under Construction..." />
    </div>
  )
}
