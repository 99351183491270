import Dashboard from "../pages/dashboard/Dashboard";
import SchedulePlanning from "../pages/schedulePlanning/SchedulePlanning";
import Users from "../pages/users/Users";
import Vessels from "../pages/Vessels";
import Company from "../pages/Company";
import MessageCenter from "../pages/MessageCenter";
import NotFound from "../pages/NotFound"
import Settings from "../pages/settings/Settings"

const Routers = [
    {
        path: '/',
        component: <Dashboard/>,
        title: "Dashboard",
    },
    {
        path: 'schedule-planning',
        component: <SchedulePlanning/>,
        title: "Schedule Planning",
    },
    {
        path: 'users',
        component: <Users/>,
        title: "Users",
    },
    {
        path: 'vessels',
        component: <Vessels/>,
        title: "Vessels",
    },
    {
        path: 'company',
        component: <Company/>,
        title: "Company",
    },
    {
        path: 'message-center',
        component: <MessageCenter/>,
        title: "Message Center",
    },
    {
        path: 'settings',
        component: <Settings/>,
        title: "Settings",
    },
    {
        path: '*',
        component: <NotFound/>,
        title: "404",
    },
]

export default Routers