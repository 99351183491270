import { withApollo } from '@apollo/client/react/hoc';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Isemail from 'isemail';
import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import zxcvbn from 'zxcvbn';
import { Actions } from '../../components/table/Actions';
import Table from '../../components/table/Table';
import LoadingIcon from "../../images/boat.gif";
import { createUser, fetchAllCompanies, getUsers } from '../../services';
import { errorToaster, successToaster } from '../../utils/messageToast';
import "./Users.scss";

const DEFAULT_USER_ROLE = "OPERATOR"
const TABLE_COLUMNS = [
  {
    Header: "First Name",
    accessor: "firstName"
  },
  {
    Header: "Last Name",
    accessor: "lastName"
  },
  {
    Header: "Username", 
    accessor: 'username'
  },
  {
    Header: "Email",
    accessor: "email"
  },
  {
    Header: "Role",
    accessor: "role"
  },
]

const TABLE_COLUMNS_SUPER_ADMIN = [...TABLE_COLUMNS, {
  Header: "Company",
  accessor: "companyName"
}]

function Users(props) {
  const originalUserInfo = useSelector(state => state.user)
  const superAdmin = useSelector(state => state.superAdmin)
  
  const [showUserPopup, setShowUserPopup] = useState(false)
  const [role, setRole] = useState(DEFAULT_USER_ROLE)
  const [pswdScore, setPswdScore] = useState(null)
  const [pswdScoreText, setPswdScoreText] = useState("")
  const [userLists, setUserLists] = useState([])
  const [loading, setLoading] = useState(true)
  const [companyList, setCompanyList] = useState([])
  const [selectedCompany, setSelectedCompany] = useState('')

  const tableColumns = useMemo(() => (superAdmin ? TABLE_COLUMNS_SUPER_ADMIN : TABLE_COLUMNS), [superAdmin])
  const tableData = useMemo(() => userLists, [userLists])
  const displayMode = useSelector(state => state.displayMode)
  const DARK_DISPLAY_MODE = 'dark'

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: {errors}
  } = useForm({ mode: "onChange"})

  useEffect(() => {
    const getAllUsers = async () => {
      setLoading(true)
      try {
        const result = await getUsers(props)
        if(!result.getUsers) return

        let resultList = [...result.getUsers]
        setUserLists(resultList.sort((a,b) => (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0)))
      } catch (error) {
        console.log(error)
      }
      setLoading(false)
    }

    const getAllCompanyUsers = async () => {
      setLoading(true)
      try {
        const result = await fetchAllCompanies(props)
        if(!result.getCompanies) return

        let userList = []
        let companies = []
        for(let company of result.getCompanies) {
          if(!company || company.users.length === 0) continue
          companies.push({
            id: company.id,
            name: company.name
          })
          for(let user of company.users) {
            userList.push({
              ...user,
              companyName: company.name
            })
          }
        }
        setCompanyList(companies)
        setUserLists(userList)
      } catch (error) {
        console.log(error)
      }
      setLoading(false)
    }

    if(superAdmin) {
      getAllCompanyUsers()
    }
    else {
      getAllUsers()
    }
    // Please do not remove the comment below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    document.title = 'RippleGo Dispatcher | Users'

    setValue('role', DEFAULT_USER_ROLE)
  }, [setValue])
  
  const checkPasswordStrength = pswd => {
    let score = zxcvbn(pswd).score
    let scoreText = ""

    switch(score) {
      case 0:
        scoreText = "very weak"
        break;
      case 1:
        scoreText = "weak"
        break;
      case 2:
        scoreText = "medium"
        break;
      case 3:
        scoreText = "strong"
        break;
      case 4:
        scoreText = "very strong"
        break;
      default:
        scoreText = ""
        break;
    }

    setValue('password1', pswd)
    setPswdScore(score)
    setPswdScoreText(scoreText)
  }

  const onSubmit = async (data) => {
    // Validate email address
    if(Isemail.validate(data.email, {errorLevel: true}) > 0) {
      setError('email', {message: "Please enter a valid email address"})
      return
    }

    // Validate password length
    if(data.password1.length < 8) {
      setError('password1', {message: "Please meet the following requirements"})
      return
    }

    // Validate password confirmation
    if(data.password1 !== data.password2) {
      setError('password2', {message: "The password confirmation does not match"})
      return
    }

    try {
      const result = await createUser(props, {
        companyId: superAdmin ? data.company : originalUserInfo.company.id,
        ...data
      })
      let addedUser = {
        ...result.createUser.user,
        companyName: superAdmin ? companyList.filter(company => company.id === data.company)[0].name : ""
      }
      let updatedList = [...userLists, addedUser]
      updatedList = updatedList.sort((a,b) => (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0))
      setUserLists(updatedList)
      setShowUserPopup(false)
      successToaster("User created successfully!")
    } catch (error) {
      errorToaster("Unable to create a user at the moment. Please try again later.")
    }
  }

  const updateUserList = async () => {
    try {
      const result = await getUsers(props)
      if(!result.getUsers) return

      let resultList = [...result.getUsers]
      setUserLists(resultList.sort((a,b) => (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0)))
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {loading &&
        <img className='loading' style={{marginTop: '20%'}} src={LoadingIcon} alt="Loading..." />
      }
      <div 
        style={{display: (loading ? "none" : "initial")}}
      >
        <h2>User Management</h2>
        <div 
          className='card shadow'
          style={{
            color : displayMode === DARK_DISPLAY_MODE? "white" : '#000A18',
            background: displayMode === DARK_DISPLAY_MODE? "#2F2F2F" : 'white'
          }}
        >
          <div className='card-body'>
            <div className='d-flex justify-content-between align-items-center'>
              <h5>User List</h5>
              <Button
                variant="contained"
                style={{fontSize: 12}}
                onClick={() => setShowUserPopup(true)}
              >
                <span className="px-2"><b>+ Add User</b></span>
              </Button>
            </div>
            <hr style={{opacity: 0.15}}/>
            <Table 
              updateList={updateUserList}
              tableProps={
                useTable(
                {
                  columns: tableColumns,
                  data: tableData,
                  stateReducer: (newState, action) => {
                    if(action.type === 'toggleRowSelected') {
                      newState.selectedRowIds = {
                        [action.id]: true
                      }
                    }
                    else {
                      newState.selectedRowIds = {}
                    }
                    return newState
                  }
                },
                useGlobalFilter,
                useSortBy,
                usePagination,
                useRowSelect,
                (hooks) => {
                  hooks.visibleColumns.push((columns) => {
                    return [
                      ...columns,
                      {
                        Header: "Actions",
                        Cell: ({row}) => (
                          <Actions {...row.getToggleRowSelectedProps()} />
                        )
                      },
                    ]
                  })
                })}
              />
          </div>
        </div>
      </div>

      <Modal
        show={showUserPopup}
        onHide={() => setShowUserPopup(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={displayMode === DARK_DISPLAY_MODE? "darkmode" : ''}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <small style={{opacity: 0.6}}>Please fill out the information below.</small>

          <form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
            <div className='d-flex justify-content-between'>
              <TextField
                {...register("firstName", {required: "Please enter your first name"})}
                label="First Name"
                variant='outlined'
                style={{
                  width: "48%"
                }}
                error={!!errors.firstName}
                helperText={errors?.firstName?.message}
                autoComplete='off'
              />

              <TextField
                {...register("lastName", {required: "Please enter your last name"})}
                label="Last Name"
                variant='outlined'
                style={{
                  width: "48%"
                }}
                error={!!errors.lastName}
                helperText={errors?.lastName?.message}
                autoComplete='off'
              />
            </div>

            <TextField
              {...register("email", {required: "Please enter a valid email address"})}
              className='text-field' 
              label="Email"
              variant='outlined'
              fullWidth={true}
              error={!!errors.email}
              helperText={errors?.email?.message}
              autoComplete='off'
            />

            <TextField
              {...register("username", {required: "Please enter username"})}
              className='text-field' 
              label="Username"
              variant='outlined'
              fullWidth={true}
              error={!!errors.username}
              helperText={errors?.username?.message}
            />

            <TextField
              {...register("password1", {required: "Please meet the following requirements"})}
              className='text-field' 
              label="Password"
              variant='outlined'
              type="password"
              autoComplete='off'
              fullWidth={true}
              error={!!errors.password1}
              helperText={errors?.password1?.message}
              onChange={(e) => {
                checkPasswordStrength(e.target.value)
              }}
            />
            <small style={{opacity: 0.7 }}>
              <ul className='mt-1'>
                <li>At least 8 characters</li>
                <li>Can't be too similar to your other personal information</li>
                <li>Can't be a commonly used password</li>
                <li>Can't be entirely numeric</li>
              </ul>
            </small>

            {pswdScoreText !== "" &&
              <div className="d-flex align-items-center ms-2 mt-1">
                <div className={"strength " + (pswdScore >= 0 ? "very-weak" : "")}/>
                <div className={"strength " + (pswdScore >= 1 ? "weak" : "")}/>
                <div className={"strength " + (pswdScore >= 2 ? "medium" : "")}/>
                <div className={"strength " + (pswdScore >= 3 ? "strong" : "")}/>
                <div className={"strength " + (pswdScore >= 4 ? "very-strong" : "")}/>
                <span style={{fontSize: 12, marginLeft: 5}}>{pswdScoreText}</span>
              </div>
            }

            <TextField
              {...register("password2", {required: "The password confirmation does not match"})}
              className='text-field' 
              label="Confirm Password"
              variant='outlined'
              type="password"
              fullWidth={true}
              error={!!errors.password2}
              helperText={errors?.password2?.message}
              autoComplete='off'
            />

            <FormControl className='text-field' fullWidth>
              <InputLabel id="role-dropdown" required>Role</InputLabel>
              <Select
                {...register("role", {required: true})}
                labelId="role-dropdown"
                value={role}
                label="Role"
                onChange={e => {
                  setRole(e.target.value)
                  setValue('role', e.target.value)
                }}
              >
                <MenuItem value="OPERATOR">OPERATOR</MenuItem>
                <MenuItem value="DISPATCHER">DISPATCHER</MenuItem>
              </Select>
            </FormControl>

            {superAdmin &&
              <FormControl className='text-field' fullWidth>
                <InputLabel id="company-dropdown" required>Company</InputLabel>
                <Select
                  {...register('company', {required: true})}
                  labelId="company-dropdown"
                  value={selectedCompany}
                  label="Company"
                  onChange={e => {
                    setSelectedCompany(e.target.value)
                    setValue('company',  e.target.value)
                  }}
                >
                  {companyList.map((company, key) => (
                    <MenuItem key={key} value={company.id}>{company.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            }

            <div className='d-flex justify-content-between mt-5'>
              <Button
                variant='contained'
                type='submit'
              >
                <span className='px-2'>Create</span>
              </Button>
              <Button onClick={() => setShowUserPopup(false)}>Close</Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default withApollo(Users)
