import { reactLocalStorage } from "reactjs-localstorage";
import {
  ADD_TRIP,
  CALCULATE_TRIP,
  CREATE_TEMPLATE,
  CREATE_USER,
  CREATE_VESSEL,
  CSV_DATA_DECKLOGS,
  CSV_DATA_PREVOYAGE,
  DELETE_TEMPLATE,
  DELETE_TRIP,
  DELETE_USER,
  DELETE_VESSEL,
  EDIT_TRIP,
  EDIT_USER,
  EDIT_VESSEL,
  FORGET_PASSWORD_MUTATION,
  GET_COMPANIES,
  GET_COMPANY,
  GET_COMPANY_TEMPLATES,
  GET_COMPANY_TRIPS,
  GET_LOCATION,
  GET_TRAFFIC,
  GET_USER,
  GET_USERS,
  GET_VESSELS,
  LOGIN_AUTH,
  REFRESH_TOKEN,
  RESET_PASSWORD,
  UPDATE_TEMPLATE,
  CHANGE_PASSWORD,
  GET_MILE_PATH
} from "../graphql/query";
import { errorHandler } from "../utils/helper";

/**
 * Auth function
 * @param {*} props pros of client
 * @param variables variable of email and password
 * @returns return token and refreshToken
 */
 export const loginAuth = async (props, variables) => {
  return props.client
    .mutate({
      variables: variables,
      context: { type: "notauth" },
      mutation: LOGIN_AUTH,
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};
/**
 * generate reset link
 * @param {*} props pros of client
 * @param variables variable of email
 * @returns return status of the response
 */
export const forgetPassword = async (props, variables) => {
  return props.client
    .mutate({
      variables: variables,
      context: { type: "notauth" },
      mutation: FORGET_PASSWORD_MUTATION,
    })
    .then((response) => response.data)
    .catch((e) => console.log("error", e));
};

/**
 * fetch vessele list
 * @param {*} props pros of client
 * @returns return vessels list
 */
export const fetchGetvessels = async (props) => {
  return props.client
    .query({
      query: GET_VESSELS,
    })
    .then((response) => response.data)
    .catch((e) => console.log("error", e));
};

/**  Get All the trips
 * @param {*} props pros of client
 * @returns return all companies
 */
 export const fetchAllCompanies = async (props) => {
  return props.client
    .query({
      query: GET_COMPANIES,
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**  Get All the company trips
 * @param {*} props pros of client
 * @returns return all trips
 */
 export const fetchCompanyTrips = async (props, variables) => {
  return props.client
    .query({
      query: GET_COMPANY_TRIPS,
      variables
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**  Get company info
 * @param {*} props pros of client
 * @returns return a company info
 */
 export const fetchCompanyInfo = async (props, variables) => {
  return props.client
    .query({
      query: GET_COMPANY,
      variables
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**  Edit user info
 * @param {*} props pros of client
 * @returns return user info
 */
 export const editUser = async (props, variables) => {
  return props.client
    .mutate({
      mutation: EDIT_USER,
      variables
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**  Delete user
 * @param {*} props pros of client
 * @returns return success boolean
 */
 export const deleteUser = async (props, variables) => {
  return props.client
    .mutate({
      mutation: DELETE_USER,
      variables
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**  Edit vessel info
 * @param {*} props pros of client
 * @returns return vessel info
 */
 export const editVessel = async (props, variables) => {
  return props.client
    .mutate({
      mutation: EDIT_VESSEL,
      variables
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**  Delete vessel
 * @param {*} props pros of client
 * @returns return success boolean
 */
 export const deleteVessel = async (props, variables) => {
  return props.client
    .mutate({
      mutation: DELETE_VESSEL,
      variables
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**  Delete trip
 * @param {*} props pros of client
 * @returns return success boolean
 */
export const deleteTrip = async (props, variables) => {
  return props.client
    .mutate({
      mutation: DELETE_TRIP,
      variables
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**
 * Password Reset with token
 *  * @param {*} props pros of client,takes a newpassword1 and newpassword
 * @returns return success or error
 */
export const PasswordReset = async (props, variables) => {
  return props.client
    .mutate({
      mutation: RESET_PASSWORD,
      variables: variables,
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**
 * Change account password when user knows the old password.
 *  * @param {*} props pros of client,takes a newpassword1 and newpassword
 * @returns return success or error
 */
export const passwordChange = async (props, variables) => {
  return props.client
    .mutate({
      mutation: CHANGE_PASSWORD,
      variables: variables,
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**
 * Add trip API call function
 * @param {*} props pros of client
 * @param variables variable of create trip data
 * @returns return status
 */
export const addTrip = async (props, variables) => {
  return props.client
    .mutate({
      variables: variables,
      mutation: ADD_TRIP,
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**
 * Create trip status API call function
 * @param {*} props pros of client
 * @param variables variable of create trip data
 * @returns return status
 */
 export const createUser = async (props, variables) => {
  return props.client
    .mutate({
      variables: variables,
      mutation: CREATE_USER,
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**
 * Create trip status API call function
 * @param {*} props pros of client
 * @param variables variable of create trip data
 * @returns return status
 */
 export const createVessel = async (props, variables) => {
  return props.client
    .mutate({
      variables: variables,
      mutation: CREATE_VESSEL,
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**
 * Calculate trip API call function
 * @param {*} props pros of client
 * @param variables variable of create trip data
 * @returns return ETA
 */
export const calculateTrip = async (props, variables) => {
  return props.client
    .query({
      variables: variables,
      query: CALCULATE_TRIP,
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**
 * Edit trip API call function
 * @param {*} props pros of client
 * @param variables variable of create trip data
 * @returns return edit trip status
 */
export const editTrip = async (props, variables) => {
  return props.client
    .mutate({
      variables: variables,
      mutation: EDIT_TRIP,
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**
 * Get traffic API call function
 * @param {*} props pros of client
 * @param variables variable of create trip data
 * @returns return ETA
 */
export const getTraffic = async (props, variables) => {
  return props.client
    .mutate({
      variables: variables,
      mutation: GET_TRAFFIC,
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

//handle Refresh Token
export const handleRefreshToken = (props) => {
  return props
    .mutate({
      variables: { refreshToken: `${reactLocalStorage.get("refreshToken")}` },
      mutation: REFRESH_TOKEN,
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e, "refreshToken", props));
};

/**
 * @param tripId
 * for downloading csv data
 */
export const getCsvDecklogs = async (props, variables) => {
  return await props.client
    .query({
      query: CSV_DATA_DECKLOGS,
      variables: variables,
      fetchPolicy: "network-only",
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**
 * @param tripId
 * for downloading csv data
 */
 export const getCsvPrevoyage = async (props, variables) => {
  return await props.client
    .query({
      query: CSV_DATA_PREVOYAGE,
      variables: variables,
      fetchPolicy: "network-only",
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**
 * @param SearchField
 * for downloading csv data
 */
export const GetSearchResult = async (props, variables) => {
  return await props.client
    .query({
      query: GET_LOCATION,
      variables: variables,
      fetchPolicy: "network-only",
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**
 * @param id
 * fetching user data
 */
export const getUser = async (props, variables) => {
  return await props.client
    .query({
      query: GET_USER,
      variables: variables,
      fetchPolicy: "network-only",
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};

/**
 * @param username
 * fetching user lists
 */
export const getUsers = async (props, variables) => {
  return await props.client
    .query({
      query: GET_USERS,
      variables,
      fetchPolicy: "network-only"
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
}

/**
 * fetching template lists
 */
export const getCompanyTemplates = async (props) => {
  return await props.client
    .query({
      query: GET_COMPANY_TEMPLATES,
      fetchPolicy: "network-only"
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
}

/**
 * create template
 */
export const createTemplate = async (props, variables) => {
  return await props.client
    .mutate({
      mutation: CREATE_TEMPLATE,
      variables,
      fetchPolicy: "network-only"
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
}

/**
 * update template
 */
export const updateTemplate = async (props, variables) => {
  return await props.client
    .mutate({
      mutation: UPDATE_TEMPLATE,
      variables,
      fetchPolicy: "network-only"
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
}


/**
 * delete template
 */
export const deleteTemplate = async (props, variables) => {
  return await props.client
    .mutate({
      mutation: DELETE_TEMPLATE,
      variables,
      fetchPolicy: "network-only"
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
}

/**
 * @param tripId
 * fetching mile markers
 */
export const getMilePath = async (props, variables) => {
  return await props.client
    .query({
      query: GET_MILE_PATH,
      variables: variables,
      fetchPolicy: "network-only",
    })
    .then((response) => response.data)
    .catch((e) => errorHandler(e));
};
