import {
  SETUP_LOGIN,
  SET_LOADING,
  SET_LOGOUT,
  SET_CURRENT_TRAFFIC_LAYER,
  SET_FILTERED_COMPANIES,
  SET_SUPER_ADMIN,
  SET_COLOR_BLIND,
  SET_UNIT_MEASUREMENT,
  SET_DISPLAY_MODE
} from "./types";

export const setupLogin = value => {
  return {
    type: SETUP_LOGIN,
    payload: value,
  };
};
export const logoutSession = () => {
  return {
    type: SET_LOGOUT
  };
};
export const setLoading = value => {
  return {
    type: SET_LOADING,
    payload: value
  }
};
export const setTrafficLayer = value => {
  return {
    type: SET_CURRENT_TRAFFIC_LAYER,
    payload: value,
  };
};
export const setFilteredCompanies = value => {
  return {
    type: SET_FILTERED_COMPANIES,
    payload: value
  }
}
export const setSuperAdmin = value => {
  return {
    type: SET_SUPER_ADMIN,
    payload: value
  }
}
export const setColorBlind = value => {
  return {
    type: SET_COLOR_BLIND,
    payload: value
  }
}
export const setUnitMeasurement = value => {
  return {
    type: SET_UNIT_MEASUREMENT,
    payload: value
  }
}
export const setDisplayMode = value => {
  return {
    type: SET_DISPLAY_MODE,
    payload: value,
  };
}; 