import { withApollo } from '@apollo/client/react/hoc';
import { faShip, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { Actions } from '../components/table/Actions';
import Table from '../components/table/Table';
import { createVessel, fetchAllCompanies, fetchGetvessels } from '../services';
import { checkVesselAvailability } from '../utils/helper';
import { successToaster } from '../utils/messageToast';
import LoadingIcon from "../images/boat.gif"

const TABLE_COLUMNS = [
  {
    Header: "Vessel Name",
    accessor: "name"
  },
  {
    Header: "MMSI",
    accessor: "mmsi"
  },
  {
    Header: "Status",
    accessor: "state"
  },
]

const TABLE_COLUMNS_SUPER_ADMIN = [...TABLE_COLUMNS, {
  Header: "Company",
  accessor: "companyName"
}]

function Vessels(props) {
  const originalUserInfo = useSelector(state => state.user)
  const superAdmin = useSelector(state => state.superAdmin)

  const [numAvailableVessels, setNumAvailableVessels] = useState(0)
  const [numInUseVessels, setNumInUseVessels] = useState(0)
  const [numRepairVessels, setNumRepairVessels] = useState(0)
  const [showVesselPopup, setShowVesselPopup] = useState(false)
  const [vesselStatus, setVesselStatus] = useState('AVAILABLE')
  const [selectedCompany, setSelectedCompany] = useState('')
  const [vesselLists, setVesselLists] = useState([])
  const [loading, setLoading] = useState(true)
  const [companyList, setCompanyList] = useState([])

  const tableColumns = useMemo(() => (superAdmin ? TABLE_COLUMNS_SUPER_ADMIN : TABLE_COLUMNS), [superAdmin])
  const tableData = useMemo(() => vesselLists, [vesselLists])

  const colorBlind = useSelector(state => state.colorBlind)
  const displayMode = useSelector(state => state.displayMode)
  const DARK_DISPLAY_MODE = 'dark'
  
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: {errors}
  } = useForm({ mode: "onChange"})

  useEffect(() => {

    const getAllVessels = async () => {
      setLoading(true)
      try {
        const result = await fetchGetvessels(props)
        if(!result.getVessels) return

        let resultList = [...result.getVessels]
        resultList = resultList.filter(v => v.isActive)
        setVesselLists(resultList.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))
      } catch (error) {
        console.log(error)
      }
      setLoading(false)
    }

    const getAllCompaniesVessels = async () => {
      setLoading(true)
      try {
        const result = await fetchAllCompanies(props)
        if(!result.getCompanies) return

        let vessels = []
        let companies = []
        for(let company of result.getCompanies) {
          if(!company || company.vessels?.length === 0) continue
          companies.push({
            id: company.id,
            name: company.name
          })
          for(let vessel of company.vessels) {
            vessels.push({
              ...vessel,
              companyName: company.name
            })
          }
        }
        setCompanyList(companies)
        setVesselLists(vessels)
      } catch (error) {
        console.log(error)
      }
      setLoading(false)
    }
    if(superAdmin) {
      getAllCompaniesVessels()
    }
    else {
      getAllVessels()
    }
    // Please do not remove the comment below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    document.title = 'RippleGo Dispatcher | Vessels'

    // Calculate num of diff status vessels
    let {available, inUse, repair} = checkVesselAvailability(vesselLists)
    setNumAvailableVessels(available)
    setNumInUseVessels(inUse)
    setNumRepairVessels(repair)
  }, [vesselLists])

  const updateVesselList = async () => {
    try {
      const result = await fetchGetvessels(props)
      if(!result.getVessels) return

      let resultList = [...result.getVessels]
      resultList = resultList.filter(v => v.isActive)
      setVesselLists(resultList.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = async (data) => {
    try {
      const result = await createVessel(props, {
        companyId: superAdmin ? data.company : originalUserInfo.company?.id,
        ...data
      })
      let addedVessel = {
        ...result.createVessel.vessel,
        companyName: superAdmin ? companyList.filter(company => company.id === data.company)[0].name : ""
      }
      let updatedList = [...vesselLists, addedVessel]
      updatedList = updatedList.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      setVesselLists(updatedList)

      setShowVesselPopup(false)
      successToaster("Vessel created successfully!")
    } catch (error) {
      setError('mmsi', {message: "This MMSI already exists"})
    }
  }
  
  return (
    <div>
      {loading &&
        <img className='loading' style={{marginTop: '20%'}} src={LoadingIcon} alt="Loading..." />
      }
      <div 
        style={{display: (loading ? "none" : "initial")}}
      >
        <h2>
          Vessel Management
        </h2>
        <div className='summary-cards'>
          <div 
            className={'card-detail ' + (colorBlind? "color-blind-card" : "")}
            style={{
              color : displayMode === DARK_DISPLAY_MODE? "white" : '#000A18',
              background: displayMode === DARK_DISPLAY_MODE? "#2F2F2F" : 'white'
            }}
          >
              <div>
                  <h6>Currently In Use</h6>
                  <h4>{numInUseVessels}</h4>
              </div>
              <FontAwesomeIcon className='card-icon' icon={faShip} style={{opacity: displayMode === DARK_DISPLAY_MODE? "1" : '0.2'}}/>
          </div>
          
          <div 
            className={'card-detail ' + (colorBlind? "color-blind-card" : "")}
            style={{
              color : displayMode === DARK_DISPLAY_MODE? "white" : '#000A18',
              background: displayMode === DARK_DISPLAY_MODE? "#2F2F2F" : 'white'
            }}
          >
            <div>
                <h6>Available</h6>
                <h4>{numAvailableVessels}</h4>
            </div>
            <FontAwesomeIcon className='card-icon' icon={faShip} style={{opacity: displayMode === DARK_DISPLAY_MODE? "1" : '0.2'}}/>
          </div>

          <div 
            className={'card-detail ' + (colorBlind? "color-blind-card" : "")}
            style={{
              color : displayMode === DARK_DISPLAY_MODE? "white" : '#000A18',
              background: displayMode === DARK_DISPLAY_MODE? "#2F2F2F" : 'white'
            }}
          >
            <div>
                <h6>Under Maintenance</h6>
                <h4>{numRepairVessels}</h4>
            </div>
            <FontAwesomeIcon className='card-icon' icon={faTriangleExclamation} style={{opacity: displayMode === DARK_DISPLAY_MODE? "1" : '0.2'}}/>
          </div>
        </div>

        <div 
          className='card shadow'
          style={{
            color : displayMode === DARK_DISPLAY_MODE? "white" : '#000A18',
            background: displayMode === DARK_DISPLAY_MODE? "#2F2F2F" : 'white'
          }}
        >
          <div className='card-body'>
            <div className='d-flex justify-content-between align-items-center'>
              <h5>Vessel List</h5>
              <Button
                variant="contained"
                style={{fontSize: 12}}
                onClick={() => setShowVesselPopup(true)}
              >
                <span className="px-2"><b>+ Add Vessel</b></span>
              </Button>
            </div>
            <hr style={{opacity: 0.15}}/>
            <Table 
              updateList={updateVesselList}
              tableProps={
                useTable(
                  {
                    columns: tableColumns, 
                    data: tableData,
                    stateReducer: (newState, action) => {
                      if(action.type === 'toggleRowSelected') {
                        newState.selectedRowIds = {
                          [action.id]: true
                        }
                      }
                      else {
                        newState.selectedRowIds = {}
                      }
                      return newState
                    }
                  },
                  useGlobalFilter,
                  useSortBy,
                  usePagination,
                  useRowSelect,
                  (hooks) => {
                    hooks.visibleColumns.push((columns) => {
                      return [
                        ...columns,
                        {
                          Header: "Actions",
                          Cell: ({row}) => (
                            <Actions {...row.getToggleRowSelectedProps()} />
                          )
                        }
                      ]
                    })
                  }
              )}
            />
          </div>
        </div>
      </div>

      <Modal
        show={showVesselPopup}
        onHide={() => setShowVesselPopup(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={displayMode === DARK_DISPLAY_MODE? "darkmode" : ''}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Vessel
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <small style={{opacity: 0.6}}>Please fill out the information below</small>

          <form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
            <TextField
              {...register('name', {required: "Please enter vessel's name"})}
              className='text-field' 
              label="Vessel Name"
              variant='outlined'
              fullWidth={true}
              error={!!errors.name}
              helperText={errors?.name?.message}
              autoComplete='off'
            />

            <TextField
              {...register('mmsi', {required: "Please enter vessel's MMSI"})}
              className='text-field' 
              label="MMSI"
              variant='outlined'
              fullWidth={true}
              error={!!errors.mmsi}
              helperText={errors?.mmsi?.message}
              autoComplete='off'
            />

            <FormControl className='text-field' fullWidth>
              <InputLabel id="status-dropdown" required>Status</InputLabel>
              <Select
                {...register('state', {required: true})}
                labelId="status-dropdown"
                value={vesselStatus}
                label="Status"
                onChange={e => {
                  setVesselStatus(e.target.value)
                  setValue('state',  e.target.value)
                }}
              >
                <MenuItem value="AVAILABLE">AVAILABLE</MenuItem>
                <MenuItem value="UNAVAILABLE">UNAVAILABLE</MenuItem>
                <MenuItem value="UNDER_REPAIR">UNDER REPAIR</MenuItem>
              </Select>
            </FormControl>

            {superAdmin &&
              <FormControl className='text-field' fullWidth>
                <InputLabel id="company-dropdown" required>Company</InputLabel>
                <Select
                  {...register('company', {required: true})}
                  labelId="company-dropdown"
                  value={selectedCompany}
                  label="Company"
                  onChange={e => {
                    setSelectedCompany(e.target.value)
                    setValue('company',  e.target.value)
                  }}
                >
                  {companyList.map((company, key) => (
                    <MenuItem key={key} value={company.id}>{company.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            }

            <div className='d-flex justify-content-between mt-5'>
              <Button
                variant="contained"
                type='submit'
              >
                <span className='px-2'>Create</span>
              </Button>
              <Button onClick={() => setShowVesselPopup(false)}>Close</Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default withApollo(Vessels)