import React from 'react'
import {NavLink, useNavigate} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faShip, faUserGroup, faSuitcase, faHouse} from "@fortawesome/free-solid-svg-icons"
import { faCalendarDays } from '@fortawesome/free-regular-svg-icons'
import LogoWhite from "../../images/logo-white.png"
import './Nav.scss'

export default function Navbar() {
  const navigate = useNavigate()

  return (
    <nav className="nav flex-column bg-secondary shadow-right">
      <div className='center py-2' style={{
        backgroundColor: "rgba(0, 10, 24, 0.5)",
        height: 50
      }}>
        <img className='pointer' src={LogoWhite} width={90} alt="RippleGo Logo" onClick={() => navigate('/')}/>
      </div>
      <div className='px-4 mt-4'>
        <NavLink className="nav-link text-white" to="/">
          <FontAwesomeIcon className='me-2 nav-icon' icon={faHouse} color="white" />
          Dashboard
        </NavLink>
        <hr className='mb-4' style={{
          border: 'none',
          backgroundColor: "white",
          height: '1px'
        }}/>
        <small className='text-white' style={{opacity: 0.5, letterSpacing: 0.5}}><b>MANAGEMENT</b></small>
        <NavLink className="mt-3 nav-link text-white" to="/schedule-planning">
          <FontAwesomeIcon className='me-2 nav-icon' icon={faCalendarDays} color="white" />
          Schedule Planning
        </NavLink>
        <NavLink className="nav-link text-white" to="/vessels">
          <FontAwesomeIcon className='me-2 nav-icon' icon={faShip} color="white" />
          Vessels
        </NavLink>
        <NavLink className="nav-link text-white" to="/users">
          <FontAwesomeIcon className='me-2 nav-icon' icon={faUserGroup} color="white" />
          Users
        </NavLink>
        <NavLink className="nav-link text-white" to="/company">
          <FontAwesomeIcon className='me-2 nav-icon' icon={faSuitcase} color="white" />
          Company
        </NavLink>
      </div>
    </nav>
  )
}
