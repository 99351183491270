import { loadModules } from 'esri-loader'
import React, { useEffect, useRef } from 'react'
import MapPin1 from "../../../images/map-pin1.svg"
import MapPin2 from "../../../images/map-pin2.svg"
import MapPin3 from "../../../images/map-pin3.svg"
import MapPin4 from "../../../images/map-pin4.svg"
import MapPin5 from "../../../images/map-pin5.svg"
import MapPin6 from "../../../images/map-pin6.svg"
import MapPin7 from "../../../images/map-pin7.svg"
import MapPin8 from "../../../images/map-pin8.svg"
import MapPin9 from "../../../images/map-pin9.svg"
import MapPin10 from "../../../images/map-pin10.svg"
import OriginPin from "../../../images/origin-pin.svg"
import { getTraffic } from '../../../services'
import { withApollo } from '@apollo/client/react/hoc'
import { useSelector } from 'react-redux'

const MAP_PINS = [MapPin1, MapPin2, MapPin3, MapPin4, MapPin4, MapPin5, MapPin6, MapPin7, MapPin8, MapPin9, MapPin10]
const DARK_DISPLAY_MODE = 'dark'

function TemplateMap({dests, origin, ...props}) {
  const mapRef = useRef(null)
  const displayMode = useSelector(state => state.displayMode)

  useEffect(() => {
    loadModules([
      'esri/Map', 
      'esri/views/MapView', 
      'esri/Graphic',
      "esri/layers/GeoJSONLayer",
      'esri/layers/GraphicsLayer', 
    ], {css: true})
    .then(([
      Map, 
      MapView, 
      Graphic,
      GeoJSONLayer,
      GraphicsLayer,
    ]) => {
      const map = new Map({ basemap: displayMode === DARK_DISPLAY_MODE ? 'dark-gray-vector' : 'gray-vector', });
      
      // Add Origin
      const originLayer = new GraphicsLayer({ 
        title: "Origin",
        graphics: {
          geometry: {
            type: 'point',
            longitude: origin.geo.coordinates[0],
            latitude: origin.geo.coordinates[1],
          },
          symbol: {
            type: "picture-marker",
            url: OriginPin,
            width: "24px",
            height: "24px"
          }
        }
      })
      map.add(originLayer)

      // Add Destinations
      const destinationsLayer = new GraphicsLayer()
      dests.forEach((point, i) => {
        const destinationPoint = new Graphic({
          geometry: {
            type: "point",
            longitude: point.destination.geo.coordinates[0],
            latitude: point.destination.geo.coordinates[1],
          },
          symbol: {
            type: "picture-marker",
            url: MAP_PINS[i],
            width: "24px",
            height: "24px"
          }
        })
        destinationsLayer.add(destinationPoint)
      })
      map.add(destinationsLayer)

      // Traffic layer
      const getTrafficLayer = async () => {
        // Get Traffic
        let source = {
          id: "traffic_layer",
          layerName:'Polyline',
          geojson: {
            type: "FeatureCollection",
            features: [],
          },
          fillstyle: {
            id: "traffic-fill-layer",
            type: "fill",
            paint: {
              "fill-color": ["get", "color"],
              "fill-opacity": 0.3,
            },
          },
          style: {
            id: "traffic-outline-layer",
            type: "line",
            paint: {
              "line-color": ["get", "color"],
              "line-width": 2,
            },
          },
        }

        let destinationsId = dests.map(d => {
          return d.destination.id
        })

        try {
          let mapTraffic = {
            type: "FeatureCollection",
            features: []
          }

          const result = await getTraffic(props, {
            origin: `POINT(${origin.geo.coordinates[0]} ${origin.geo.coordinates[1]})`,
            destinations: destinationsId,
          })

          let urls = []
          for(let route of result.getTraffic) {
            urls.push(`${process.env.REACT_APP_JSON_CDN}${route.id}.json`)
          }
    
          await Promise.all(urls.map (url => 
            fetch(url)
            .then(response => response.json())
          ))
          .then(data => {
            
            // Update the color for each channel reach
            for(let index in result.getTraffic) {
              data[index].properties.color = result.getTraffic[index].color
              source.geojson.features = data
              mapTraffic.features.push(data[index])
            }
          })

          const blob = new Blob([JSON.stringify(mapTraffic)], {
            type: "application/json"
          });
          const trafficLayerUrl = URL.createObjectURL(blob);
          const trafficLayerID = "traffic-layer"
        
          const tripTrafficLayer = new GeoJSONLayer({
            id: trafficLayerID,
            title: "Traffic",
            url: trafficLayerUrl,
            renderer: {
              type: 'unique-value',
              field: 'color',
              uniqueValueInfos: [{
                value: "#00ff00",
                symbol: {
                  type: 'simple-fill',
                  color: [0, 255, 0, 0.5],
                  outline: {
                    color: [0, 255, 0],
                    width: 1.5
                  },
                },
              },
              {
                value: "#ffff00",
                symbol: {
                  type: 'simple-fill',
                  color: [255, 255, 0, 0.5],
                  outline: {
                    color: [255, 255, 0],
                    width: 1.5
                  },
                },
              },
              {
                value: "#ff0000",
                symbol: {
                  type: 'simple-fill',
                  color: [255, 0, 0, 0.5],
                  outline: {
                    color: [255, 0, 0],
                    width: 1.5
                  },
                },
              }
              ]
            },
          });
          map.add(tripTrafficLayer, -1)
        } catch (error) {
          console.log(error)
        }
      }
      getTrafficLayer()

      let center = dests[0].destination.geo.coordinates
      let zoom = 5
      if(dests.length > 2) {
        center = dests[Math.round(dests.length / 2)].destination.geo.coordinates
        zoom = 4
      }
      new MapView({
        container: mapRef.current,
        map: map,
        center,
        zoom,
        constraints: {
          snapToZoom: false,
          rotationEnabled: false
        },
        ui: {
          components: []
        },
      });
    })

    // Please do not remove the comment below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div ref={mapRef}
      className='template-modal-map'
    />
  )
}

export default withApollo(TemplateMap)