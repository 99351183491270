import React, { useEffect, useRef, useState } from 'react'
import { isEmpty } from 'lodash';
import Highcharts from 'highcharts/highcharts-gantt';
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment';
import TripModal from '../TripModal';
import { checkEndDate, findOverlappingDateRanges } from '../../../utils/helper';
import { useSelector } from 'react-redux';

const VESSEL_TAB = "vessel"
const DARK_DISPLAY_MODE = 'dark'

const DEFAULT_PLOTOPTIONS = {
    series: {
      dataLabels: {
        enabled: true,
        format: '{point.name}',
        style: {
          fontWeight: 'normal',
          fontSize: '13px',
          textOutline: "0px",
        },  
      }
    }
}

export default function VesselScheduler({tripsData, handleTripUpdate, checkOverlapping}) {
    const chartRef = useRef(null)
    const [selectedTrip, setSelectedTrip] = useState({})
    const [showTripModal, setShowTripModal] = useState(false)
    const [disableTripEdit, setDisableTripEdit] = useState(false)
    const colorBlind = useSelector(state => state.colorBlind)
    const displayMode = useSelector(state => state.displayMode)
    const [chartOptions, setChartOptions] = useState({
        chart: {
          backgroundColor: displayMode === DARK_DISPLAY_MODE? "#2F2F2F" : '#FFFFFF',
        },
        xAxis: {
          tickPixelInterval: 70,
          labels: {
            style: {
              color: displayMode === DARK_DISPLAY_MODE? '#FFFFFF' : 'black',
            }
          }
        },
        yAxis: {
          type: 'category',
          grid: {
            enabled: true,
            borderColor: 'rgba(0,0,0,0.2)',
            borderWidth: 1,
            columns: [
              {
                title: {
                  text: 'Vessel Name'
                },
                labels: {
                  format: '{point.vesselName}'
                }
              }, 
              {
                title: {
                  text: '# of Trips'
                },
                labels: {
                  format: '{point.count}'
                }
              }
            ]
          },
          labels: {
            style: {
              color: displayMode === DARK_DISPLAY_MODE? '#FFFFFF' : 'black',
            }
          }
        },
        accessibility: {
          enabled: false
        },
        navigator: {
          enabled: true,
          liveRedraw: true,
          series: {
            type: 'gantt',
            pointPlacement: 0.5,
            pointPadding: 0.25,
            accessibility: {
              enabled: false
            },
          },
          yAxis: {
            min: 0,
            max: 3,
            reversed: true,
            categories: []
          }
        },
        scrollbar: {
          enabled: true
        },
        rangeSelector: {
          enabled: true,
          selected: 5
        },
        plotOptions: {
          series: {
            ...DEFAULT_PLOTOPTIONS.series,
            events: {
              click: e => {
                setDisableTripEdit((e.point.statuses.length > 0 && e.point.statuses.at(-1).status === 'END') ? true : false)
                setSelectedTrip(e.point)
                setShowTripModal(true)
              }
            }
          }
        },
        tooltip: {
          useHTML: true,
          headerFormat: "",
          pointFormat: `
            <div>
              Trip name: <b>{point.name}</b> <br/>
              Origin: <b>{point.origin.name}</b> <br/>
              Destination: <b>{point.destination.name}</b> <br/>
              Operator: <b>{point.operator}</b> <br/>
              Vessel: <b>{point.vessel.name} ({point.vessel.mmsi})</b> <br/>
              Departure Date: <b>{point.departureDate}</b> <br/>
              ETA/Arrival: <b>{point.end_date}</b> <br/>
              Status: <b>{point.status}</b> <br/>
              Last updated at: <b>{point.lastUpdatedAt}</b> <br/>
            </div>
          ` 
        },
        series: [{
          data: []
        }],
        exporting: {
          sourceWidth: 1000
        },
        credits: {
          enabled: false
        },
    })

    useEffect(() => {
        const trips = [...tripsData]
        let seriesData = []
        let y = 0
        let vesselIndex = new Map()

        // Set vessel YAxis index and count
        for(let trip of trips) {
          let end_date = checkEndDate(trip.destinations, trip.departureDate)

          if(!vesselIndex.has(trip.vessel.name)) {
            vesselIndex.set(trip.vessel.name, {
              index: y,
              count: 1,
              sd_ed: [{
                sd: moment(trip.departureDate),
                ed: end_date
              }]
            })
            y++
          }
          else {
            vesselIndex.set(trip.vessel.name, {
              ...vesselIndex.get(trip.vessel.name),
              count: vesselIndex.get(trip.vessel.name).count + 1,
              sd_ed: [
                ...vesselIndex.get(trip.vessel.name).sd_ed,
                {
                  sd: moment(trip.departureDate),
                  ed: end_date
                }
              ]
            })
          }
        }

        // Check if there are overlapping trips with the same vessel
        let hasOverlapping = false
        for(const [key,val] of vesselIndex) {
          const overlappingIndexes = findOverlappingDateRanges(val.sd_ed);
          vesselIndex.set(key, {
            ...val,
            overlap: overlappingIndexes
          })

          if(overlappingIndexes.length > 0) {
            hasOverlapping = true
          }
        }
        checkOverlapping(VESSEL_TAB, hasOverlapping)

        for(let trip of trips) {
            if(trip.destinations.length === 0) continue

            let end_date = checkEndDate(trip.destinations, trip.departureDate)
            let color = displayMode === DARK_DISPLAY_MODE? 'rgba(255, 255, 255, 0)' : 'white' // gray
            let borderColor = (colorBlind? "#19168c":"#008F85") // ripplego green

            if(trip.isActive) {
                color = trip.statuses.at(-1).status === "PAUSE" ? '#CD2C2C' : (colorBlind? "#19168c":"#008F85") // red : ripplego green
                borderColor = color
            }

            if(trip.statuses.length > 0 && trip.statuses.at(-1).status === 'END') {
                color = "#A4A4A4" 
                borderColor = color
            }

            // Display the warning sign if the trip is overlapping
            let hasOverlap = false
            if(vesselIndex.get(trip.vessel.name).overlap.length > 0) {
              for(let overlapIndex of vesselIndex.get(trip.vessel.name).overlap) {
                hasOverlap = moment(trip.departureDate).isSame(vesselIndex.get(trip.vessel.name).sd_ed[overlapIndex].sd, 'date')
                if(hasOverlap) {
                  checkOverlapping(VESSEL_TAB, hasOverlap)
                  break
                }
              }
            }

            seriesData.push({
                ...trip,
                departureDate: moment(trip.departureDate).format("MMM DD, YYYY HH:mm"),
                lastUpdatedAt: moment(trip.positionUpdatedAt).format("MMM DD, YYYY HH:mm"),
                operator: trip.user.firstName ? (trip.user.firstName + " " + trip.user.lastName) : trip.user.username,
                destination: trip.destinations.at(-1).destination,
                status: trip.statuses.length > 0 ? trip.statuses.at(-1).status : "",
                start: new Date(trip.departureDate).getTime(),
                end: new Date(end_date).getTime(),
                end_date: end_date.format('MMM DD, YYYY HH:mm'),
                vesselName: trip.vessel.name,
                name: (hasOverlap ? "⚠️ " : "") + trip.name,
                tripName: trip.name,
                color,
                borderColor: borderColor,
                pointWidth: 40,
                count: vesselIndex.get(trip.vessel.name).count,
                y: vesselIndex.get(trip.vessel.name).index,
                dataLabels: {
                  enabled: true,
                  color: displayMode === DARK_DISPLAY_MODE? '#FFFFFF' : 'black'
                }
            })
        }

        setChartOptions({
            series: [{
              data: seriesData
            }],
            plotOptions: {
              DEFAULT_PLOTOPTIONS
            }
        })
    }, [tripsData, checkOverlapping, colorBlind, displayMode])

    return (
        <>
            <HighchartsReact
                ref={chartRef}
                highcharts={Highcharts}
                constructorType="ganttChart"
                options={chartOptions}
            />

            {!isEmpty(selectedTrip) &&
                <TripModal 
                    show={showTripModal} 
                    onHide={() => setShowTripModal(false)} 
                    tripInfo={selectedTrip} 
                    disabled={disableTripEdit}
                    handleTripUpdate={(trip, type) => {
                      setShowTripModal(false)
                      handleTripUpdate(trip, type)
                    }}
                />
            }
        </>
    )
}
