import React, {useState, useEffect} from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { faArrowsUpDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LocationSearch from './LocationSearch';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';

const DESTINATION_FIELD = 'DESTINATIONS'

export default function DraggableInputs({
  handleSearchLocationOnClick,
  removeDestination,
  helperText,
  error,
  clearErrors,
  register,
  existingDestinations,
  disabled,
  onDragEnd,
}) {
  const [destinations, setDestinations] = useState(existingDestinations.length > 0 ? existingDestinations : [{}])
  const colorBlind = useSelector(state => state.colorBlind)

  useEffect(() => {
    let destinations = existingDestinations.length > 0 ? [...existingDestinations] : [{}]

    if(destinations.length > 1) {
      destinations = destinations.sort((a, b) => a.legNumber - b.legNumber)
    }
    
    setDestinations(destinations)

  }, [existingDestinations])

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "#F8F9FA" : "white",
    ...draggableStyle
  });

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            >
              {destinations.map((dest, key) => (
                <Draggable key={key} draggableId={key.toString()} index={key}>
                  {(provided, snapshot) => (
                    <div
                      className='d-flex align-items-end'
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                      )}
                      >
                      <div {...provided.dragHandleProps}>
                        {(!disabled && destinations?.length > 1) &&
                          <FontAwesomeIcon className="me-2" icon={faArrowsUpDown} color={colorBlind? "#19168c":"#008F85"} size='sm' style={{marginBottom: 6}}/>
                        }
                      </div>
                      <LocationSearch 
                        {...register} 
                        error={error}
                        helperText={helperText}
                        handleSearchLocationOnClick={handleSearchLocationOnClick} 
                        type={DESTINATION_FIELD} 
                        removeDestination={() => { removeDestination(key) }}
                        destNum={key+1}
                        clearErrors={clearErrors}
                        locationName={dest?.destination}
                        disabled={disabled}
                        key={key}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {!disabled &&
        <Button
          variant='outlined'
          className='mt-2 p-0'
          onClick={() => { setDestinations(prevArr => [...prevArr, {}]) }}
        >
          <div style={{fontSize: 18}}><b>+</b></div>
        </Button>
      }

    </>
  )
}
